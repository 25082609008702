import { APIIngestHelper } from "hooks";

import {
  DiscoverResult,
  Reader,
  loadStripeTerminal,
  StripeTerminal,
  Terminal,
} from "@stripe/terminal-js";

var useSimulatedCardReader: boolean = false;

//const API_URL = "https://nexijaccd4.execute-api.us-east-1.amazonaws.com/ggc";
//const API_URL = "http://localhost:3000"; // Local testing
//const API_URL = "https://googooapi.fivestonestudios.com"; // Fivestone intermediate hosting
const API_URL = "https://api.googoo.com"; // Final EC2 server hosting

enum APIRequests {
  ECHO = "echo",
  GET_OPTIONS = "options-get",
  CART_CREATE = "cart-create",
  CART_UPDATE = "cart-update",
  CART_GET = "cart-get",
  CART_DELETE = "cart-delete",
  CART_COMPLETE = "cart-complete",
  CART_CUSTOMER = "cart-customer",
  STRIPE_GET_TOKEN = "stripe-get-token",
  STRIPE_CREATE_INTENT = "stripe-create-intent",
  STRIPE_CAPTURE_INTENT = "stripe-capture-intent",
  STRIPE_CANCEL_INTENT = "stripe-cancel-intent",
  STRIPE_LIST_INTENTS = "stripe-list-intents",
}

export enum ProductType {
  SHELLS = "ChocolateShells",
  ADDINS = "Add-Ins",
  FILLINGS = "Fillings",
  ADDONS = "Add-Ons",
}

export class ShopifyVariant {
  id: number;
  title: string;
  price: number;
  description: string;
  available: boolean;

  constructor(
    id: number,
    title: string,
    price: number,
    description: string,
    available: boolean
  ) {
    this.id = id;
    this.title = title;
    this.price = price;
    this.description = description;
    this.available = available;
  }
}

export class ShopifyProduct {
  id: number;
  title: string;
  variants: ShopifyVariant[];
  quantity: number;
  originalUnitPrice?: number;

  constructor(id: number, title: string, variants: ShopifyVariant[]) {
    this.id = id;
    this.title = title;
    this.variants = variants;
  }
}

export class ShopifyCart {
  id: number;
  lineItems: ShopifyProduct[];
  subtotalPrice: number;
  totalTax: number;
  totalPrice: number;
  dinein: boolean;

  constructor() {
    this.id = 0;
    this.lineItems = [];
    this.subtotalPrice = 0;
    this.totalTax = 0;
    this.totalPrice = 0;
    this.dinein = true;
  }
}

export class PaymentIntentData {
  id: string;
  amount: number;
  cartID: string;
  status: string;
  created: number;
  canceled_at: string;
}

export default class GGCStoreFront {
  static instance: GGCStoreFront = null;
  StripeTerminal: StripeTerminal;
  terminal: Terminal;
  readers: Reader[];
  isReaderConnected: boolean;
  paymentIntents: PaymentIntentData[];

  Products: ShopifyProduct[];
  Cart: ShopifyCart = new ShopifyCart();
  ProductUID: number;
  paymentIntentID: any;

  static getInstance(): GGCStoreFront {
    if (GGCStoreFront.instance == null) {
      GGCStoreFront.instance = new GGCStoreFront();
    }

    return this.instance;
  }

  static getReaderSerialNumber(): string {
    return localStorage.getItem("readerID");
  }

  static setReaderSerialNumber(readerID: string) {
    localStorage.setItem("readerID", readerID);
  }

  /** Helper function for sending requests to the ggc storefront api */
  private async sendReq(reqURL: string, req: any): Promise<any> {
    let response = await fetch(`${API_URL}/${reqURL}`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });

    let data = await response.json();
    return data;
  }

  /** Initializes the shopify product options */
  async initialize(contextFunction: any, callback: () => void) {
    let data = await this.sendReq(APIRequests.GET_OPTIONS, {});

    this.Products = data as ShopifyProduct[];
    console.log(this.Products);
    APIIngestHelper(contextFunction);

    if (callback) {
      callback();
    }
  }

  async initializeStripe(callback: () => void) {
    this.StripeTerminal = await loadStripeTerminal();

    this.terminal = this.StripeTerminal.create({
      onFetchConnectionToken: this.fetchConnectionToken,
      onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
    });

    if (callback) {
      callback();
    }
  }

  private fetchConnectionToken() {
    // Do not cache or hardcode the ConnectionToken. The SDK manages the ConnectionToken's lifecycle.
    return fetch(`${API_URL}/${APIRequests.STRIPE_GET_TOKEN}`, {
      method: "POST",
    })
      .then(function (response) {
        console.log("FetchConnectionToken");
        console.log(response);
        return response.json();
      })
      .then(function (data) {
        return data.client_secret;
      });
  }

  private unexpectedDisconnect() {
    // In this function, your app should notify the user that the reader disconnected.
    // You can also include a way to attempt to reconnect to a reader.
    // alert("Disconnected from reader");
    console.log("disconnted from reader");
  }

  async discoverCardReaders() {
    console.log("Discovering readers");

    var config = {
      simulated: useSimulatedCardReader,
    };
    let result = (await this.terminal?.discoverReaders(config)) as any;

    if (result?.error) {
      console.log("Failed to discover: ", result.error);
    } else if (result?.discoveredReaders.length === 0) {
      console.log("No available readers.");
    } else {
      let discoverResult = result as DiscoverResult;
      this.readers = discoverResult?.discoveredReaders;
      console.log("terminal.discoverReaders", this.readers);
    }
  }

  async connectCardReader() {
    var serial_number = GGCStoreFront.getReaderSerialNumber();

    if (this.readers === undefined || this.readers.length === 0) {
      await this.discoverCardReaders();

      if (this.readers?.length === 0) {
        // alert("Failed to connect to a reader");
        return;
      }
    }

    if (this.isReaderConnected) {
      //   for (var i = 0; i < this.readers.length; i++) {
      //     if (
      //       this.readers[i].serial_number !== serial_number &&
      //       this.readers[i].status === "connected"
      //     ) {
      this.disconnectCardReader();
      //     }
      //   }
      //   return;
    }

    this.isReaderConnected = false;

    if (serial_number == null) {
      // alert("Failed to load reader ID");
      return;
    }

    for (var i = 0; i < this.readers.length; i++) {
      if (
        this.readers[i].serial_number === serial_number ||
        (i === 0 && useSimulatedCardReader)
      ) {
        let result = (await this.terminal.connectReader(
          this.readers[i]
        )) as any;

        if (result.error) {
          // alert("Failed to connect: " + JSON.stringify(result.error));
          break;
        } else {
          console.log("Connected to reader: ", result.reader.label);
          console.log("terminal.connectReader", result);
          this.isReaderConnected = true;
          break;
        }
      }
    }

    if (this.isReaderConnected === false) {
      // alert("Failed to connect a card reader");
      return;
    }
  }

  async disconnectCardReader() {
    var serial_number = GGCStoreFront.getReaderSerialNumber();

    for (var i = 0; i < this.readers.length; i++) {
      if (this.readers[i].serial_number === serial_number) {
        let result = (await this.terminal.disconnectReader()) as any;

        if (result.error) {
          // alert("Failed to disconnect: " + result.error);
          break;
        } else {
          // alert("Disconnected from reader " + serial_number);
          this.isReaderConnected = false;
          break;
        }
      }
    }
  }

  public async listPaymentIntents() {
    let response = await fetch(`${API_URL}/${APIRequests.STRIPE_LIST_INTENTS}`);
    let paymentIntentsData = await response.json();
    this.paymentIntents = paymentIntentsData as any as PaymentIntentData[];
    return response;
  }

  private async fetchPaymentIntentData() {
    let req = {
      cartID: this.Cart.id,
    };

    let response = await fetch(
      `${API_URL}/${APIRequests.STRIPE_CREATE_INTENT}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
      }
    );
    let data = await response.json();
    return data;
  }

  async collectPayment(callback: (success: boolean, error: string) => void) {
    let paymentIntentData = await this.fetchPaymentIntentData();
    let clientSecret = paymentIntentData.client_secret;
    this.paymentIntentID = paymentIntentData.paymentIntentID;

    if (useSimulatedCardReader) {
      const testCard = "4242424242424242"; // Succeed
      //const testCard = "4000002500003155"; // Requires Auth
      //const testCard = "4000000000009995"; // Declined

      this.terminal.setSimulatorConfiguration({
        testCardNumber: testCard,
      });
    }

    let result = (await this.terminal.collectPaymentMethod(
      clientSecret
    )) as any;

    if (result.error) {
      // Placeholder for handling result.error
      console.log(result.error);
    } else {
      console.log("terminal.collectPaymentMethod", result.paymentIntent);

      let ppResult = (await this.terminal.processPayment(
        result.paymentIntent
      )) as any;
      if (ppResult.error) {
        console.log(ppResult.error);
        callback(false, ppResult.error?.message);
      } else if (ppResult.paymentIntent) {
        console.log("terminal.processPayment", ppResult.paymentIntent);
        await this.capturePayment(ppResult.paymentIntent.id);
        callback(true, null);
      }
    }
  }

  async capturePayment(paymentIntentID: string) {
    let req = {
      cartID: this.Cart.id,
      paymentIntentID: paymentIntentID,
      dinein: this.Cart.dinein,
    };
    let response = await fetch(
      `${API_URL}/${APIRequests.STRIPE_CAPTURE_INTENT}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(req),
      }
    );

    //let data = await response.json();

    console.log("server.capture", response);
  }

  async cancelPayment(
    paymentIntentID: string,
    deleteCart: boolean = true
  ): Promise<any> {
    let req = {
      cartID: this.Cart.id,
      paymentIntentID: paymentIntentID,
      deleteCart: deleteCart,
    };

    await this.terminal.cancelCollectPaymentMethod().then((error) => {
      if (error) {
        console.log(error);
      }
    });

    await fetch(`${API_URL}/${APIRequests.STRIPE_CANCEL_INTENT}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    });
  }

  /** Returns product options by type */
  getOptions(type: ProductType): ShopifyVariant[] {
    let variants: ShopifyVariant[] = [];

    if (this.Products !== null && this.Products.length > 0) {
      this.Products.forEach((product: ShopifyProduct) => {
        if (product.title === type.toString()) {
          variants = product.variants;
        }
      });
    }

    return variants;
  }

  /** Adds a new custom goo goo product to the cart. If a cart has not been created, create one */
  addGooGoo(
    title: string,
    quantity: number,
    options: ShopifyVariant[],
    callback: (cart: ShopifyCart) => void
  ) {
    const createCart = this.Cart
      ? this.Cart.id !== 0 || this.Cart.id !== undefined
      : true;

    let product: ShopifyProduct = {
      id: this.ProductUID++,
      title: title,
      variants: options,
      quantity: quantity,
    };

    this.Cart.lineItems.push(product);

    if (createCart) {
      this.createCart(callback);
    } else {
      this.updateCart(callback);
    }
  }

  /** Updates a custom goo goo product by line item ID */
  updateGooGoo(
    lineItemID: number,
    title: string,
    quantity: number,
    options: ShopifyVariant[],
    callback: (cart: ShopifyCart) => void
  ) {
    for (var i = 0; i < this.Cart.lineItems.length; i++) {
      let lineItem = this.Cart.lineItems[i];

      if (lineItem.id === lineItemID) {
        lineItem.variants = options;
        lineItem.title = title;
        lineItem.quantity = quantity;
        break;
      }
    }

    this.updateCart(callback);
  }

  /** Removes a custom goo goo product by line item ID */
  removeGooGoo(lineItemID: number, callback: (cart: ShopifyCart) => void) {
    for (var i = 0; i < this.Cart.lineItems.length; i++) {
      let lineItem = this.Cart.lineItems[i];

      if (lineItem.id === lineItemID) {
        this.Cart.lineItems.splice(i, 1);
        break;
      }
    }

    this.updateCart(callback);
  }

  /** Copies all the cart properties minus the line items as we store those as shopify products */
  private updateCartAttributes(cart: ShopifyCart) {
    this.Cart.id = cart.id;
    this.Cart.subtotalPrice = cart.subtotalPrice;
    this.Cart.totalPrice = cart.totalPrice;
    this.Cart.totalTax = cart.totalTax;

    // For the line items, we're only interested in the unique id per custom goo goo product
    if (cart.lineItems) {
      for (var i = 0; i < cart.lineItems.length; i++) {
        if (this.Cart.lineItems.length <= i) break;

        this.Cart.lineItems[i].id = cart.lineItems[i].id;
      }
    }
  }

  /** Creates the shopify cart */
  async createCart(callback: any) {
    let req = {
      lineItems: this.Cart.lineItems,
      kiosk: GGCStoreFront.getReaderSerialNumber() !== null,
    };

    let response = await this.sendReq(APIRequests.CART_CREATE, req);
    let cart = response as ShopifyCart;
    this.updateCartAttributes(cart);
    callback(response);
  }

  /** Updates the shopify cart */
  async updateCart(callback: any) {
    let req = {
      cartID: this.Cart.id,
      lineItems: this.Cart.lineItems,
    };

    let response = await this.sendReq(APIRequests.CART_UPDATE, req);
    let cart = response as ShopifyCart;
    this.updateCartAttributes(cart);
    callback(response);
  }

  /** Deletes the shopify cart */
  async deleteCart(callback: any) {
    // If a cart hasn't been started, error out
    if (this.Cart.id === 0) {
      if (callback) {
        console.error(`A cart has not been started.`);
        return;
      }
    }

    let req = {
      cartID: this.Cart.id,
    };

    await this.sendReq(APIRequests.CART_DELETE, req);

    this.Cart = new ShopifyCart();
    callback();
  }

  /** Used for testing round trip of data to api and back again */
  async Echo() {
    console.log("ECHOING");

    let req = {
      someVar: "someValue",
    };

    await this.sendReq(APIRequests.ECHO, req);
  }

  /** Creates a customer for the shopify order */
  async addCustomer(
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    acceptsMarketing: boolean,
    callback: any
  ) {
    let req = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      acceptsMarketing: acceptsMarketing,
      cartID: this.Cart.id,
    };

    await this.sendReq(APIRequests.CART_CUSTOMER, req);

    callback();
  }

  async setDineIn(isOrderDineIn: boolean, callback: () => void) {
    this.Cart.dinein = isOrderDineIn;
  }
}
