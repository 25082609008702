import GGCStoreFront, { ProductType } from "services/ggc-storefront-api";

enum ImgDirectory {
  id40098425045197 = "Dark_Chocolate_Shell",
  id40098425077965 = "Milk_Chocolate_Shell",
  id40205058244813 = "RubyChocolate",
  id40205059817677 = "CaramelRoastedWhiteChoc",
  id40205060145357 = "WhiteChocolate",

  id40098424029389 = "Butterfinger_Pieces",
  id40098424062157 = "Bacon_Fat_Caramel",
  id40098424094925 = "Banana_Pudding_Ganache",
  id40098424127693 = "Banana_Nougat",
  id40098424160461 = "Bar-B-Cutie_Hot_Sauce_Caramel",
  id40098424193229 = "Bourbon_Caramel",
  id40098424225997 = "Bourbon_Peach_Ganache",
  id40098424258765 = "Brown_Sugar_Nougat",
  id40098424291533 = "Cheerwine_Cherry_Caramel",
  id40098424324301 = "Cheerwine_Cherry_Nougat",
  id40098424357069 = "Chocolate_Nougat",
  id40098424389837 = "Chocolate-Hazelnut_Cream",
  id40098424422605 = "Coffee_Honey_Ganache",
  id40098424455373 = "Coffee_Nougat",
  id40098424488141 = "Cookie_Butter_Filling",
  id40098424520909 = "Dark_Chocolate_Ganache",
  id40098424553677 = "Earl_Grey_Ganache",
  id40098424586445 = "Malted_Nougat",
  id40098424619213 = "Orange_Peel_Nougat",
  id40098424651981 = "Pate_De_Frut",
  id40098424684749 = "Peanut_Butter_Caramel",
  id40098424717517 = "Peanut_Butter_Cream",
  id40098424750285 = "Pecan_Praline_Nougat",
  id40098424783053 = "Peppermint_Ganache",
  id40098424815821 = "Pineapple_Caramel",
  id40098424848589 = "Red_Velvet_Fudge",
  id40098424881357 = "Ricotta_Ganache",
  id40098424914125 = "Salted_Caramel",
  id40098424946893 = "Spiced_Chai_Nougat",
  id40098424979661 = "Vanilla_Nougat",
  id40098425012429 = "White_Chocolate_Ganache",
  id40205203407053 = "Maple_Caramel",
  id40205203439821 = "Chocolate_Caramel",
  id40205204685005 = "Spiced_Caramel",
  id40205206159565 = "Vanilla_Malted_Nougat",
  id40244237107405 = "Dark_Chocolate_Peppermint_Ganache",

  id40098422227149 = "Almonds",
  id40098422259917 = "Animal_Crackers",
  id40098422292685 = "Banana_Chips",
  id40098422325453 = "Blondie_Bits",
  id40098422358221 = "Brownie_Bits",
  id40098422390989 = "Butterscotch_Chips",
  id40098422423757 = "Candied_Ginger",
  id40098422456525 = "Candy_Corn",
  id40098422489293 = "Cannoli_Shell_Pieces",
  id40098422522061 = "Caramel_Bits",
  id40098422554829 = "Caramel_Chocolate_Crispy_Pearl",
  id40098422587597 = "Caramel_Corn",
  id40098422620365 = "Caramel_Toffee_Crunch",
  id40098422653133 = "Cashews",
  id40098422685901 = "Chocolate_Chip_Cookie_Dough",
  id40098422718669 = "Chocolate_Chip_Cookie_Pieces",
  id40098422784205 = "Cinnamon_Toast_Crunch",
  id40098422816973 = "Cocoa_Pebbles",
  id40098422849741 = "Cocoa_Puffs",
  id40098422882509 = "Coconut_Flakes",
  id40098422915277 = "Coconut_Macaroons",
  id40098422948045 = "Dark_Chocolate_Crispy_Pearl",
  id40098422980813 = "Dried_Fruit",
  id40098423013581 = "Frosted_Flakes",
  id40098423046349 = "Fruit_Loops",
  id40098423079117 = "Fruit_Pebbles",
  id40098423111885 = "Giffords_Bacon_Bits",
  id40098423144653 = "Gingerbread_Pieces",
  id40098423177421 = "Gummy_Bears",
  id40098423210189 = "Gummy_Worms",
  id40098423242957 = "Hazelnuts",
  id40098423275725 = "Honey_Roasted_Peanuts",
  id40098423308493 = "Honeycomb_Cereal",
  id40098423341261 = "Kix",
  id40098423374029 = "Lucky_Charms",
  id40098423406797 = "Milk_Choc_Crispy_Pearl",
  id40098423439565 = "Mini_Chocolate_Chips",
  id40098423472333 = "Mini_Marshmallows",
  id40098423505101 = "Oreo_Pieces",
  id40098423537869 = "PB_GooGoo_Cluster_Pieces",
  id40098423570637 = "Peanuts",
  id40098423603405 = "Pecans",
  id40098423636173 = "Pistachios",
  id40098423668941 = "Potato_Chips",
  id40098423701709 = "Pumpkin_Seeds",
  id40098423767245 = "Rice_Krispies",
  id40098423800013 = "Vanilla_Wafer",
  id40098423832781 = "Teddy_Grahams",
  id40098423865549 = "Toffee_Pieces",
  id40098423898317 = "Waffle_Cone_Pieces",
  id40098423931085 = "Walnuts",
  id40363570692301 = "Rainbow_Sprinkles",

  id40230146048205 = "Butterfinger_Pieces",
  id40230152175821 = "Fudge",
  id40098423963853 = "White_Chocolate_Crispy_PEarl",
  id40098423996621 = "White_Chocolate_Chips",
  id40244285243597 = "Pretzels",

  id40098421965005 = "Gold_Leaf",
  id40098421997773 = "Sea_Salt",
  id40098422030541 = "Crushed_Peppermint",
  id40098422063309 = "Sprinkles",
  id40098422096077 = "Candied_Orange_Peels",
  id40098422128845 = "Candied_Cocoa_Nibs",
  id40098422161613 = "Pop_Rocks",
  id40098422194381 = "Espresso_Powder",
  id40249339347149 = "Mothers.Valentines_Day_Sprinkles",
  id40249340264653 = "Halloween_Sprinkles",
  id40249341575373 = "Christmas_Sprinkles",
  id40249342066893 = "Fourth_of_July_Sprinkles",
  id40249338888397 = "Rainbow_Sprinkles",
  id40249338233037 = "Chocolate_Sprinkles",
  id40363541954765 = "Dark_Chocolate_Crispy_Pearl",
}

export default function APIIngestHelper(contextFunction: any) {
  let Ingredients: any = {};

  // Use get options to pull data from service, much safer
  Ingredients.shells = GGCStoreFront.getInstance().getOptions(
    ProductType.SHELLS
  );
  Ingredients.addIns = GGCStoreFront.getInstance().getOptions(
    ProductType.ADDINS
  );
  Ingredients.sweets = GGCStoreFront.getInstance().getOptions(
    ProductType.FILLINGS
  );
  Ingredients.addOns = GGCStoreFront.getInstance().getOptions(
    ProductType.ADDONS
  );

  let tempData: any = [
    ...Ingredients.shells,
    ...Ingredients.sweets,
    ...Ingredients.addIns,
    ...Ingredients.addOns,
  ];

  dataHelper(tempData);
  contextFunction("ingredients", Ingredients);
}

function dataHelper(array: any) {
  array.forEach((e: any, i: number) => {
    const id = ("id" + e.id.split("/").pop()) as keyof typeof ImgDirectory;
    array[i].src = ImgDirectory[id];
    array[i].inventoryQuantity = 0;

    //console.log(` ${id} = ${e.title.split(" ").join("_")},`);
  });
}
