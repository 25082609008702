import { ContextData } from "data/Context";
import { useContext, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "components";

export default function NamerPopup({
  active,
  title,
  copy,
  back,
  forward,
  swiper,
  closePopUp,
  stateFunction,
}: any) {
  const contextReact = useContext(ContextData);
  let history = useHistory();
  const textInput = useRef(null);
  const [textActive, setTextActive] = useState(false);

  return (
    <div
      className={`configurator-backgroundModel  ${
        active ? "active" : "unActive"
      }`}
      style={{
        pointerEvents: active ? "all" : "none",
        opacity: active ? "1" : "0",
      }}
    >
      <div className="configurator-slide ">
        <div className="configurator-slideTitle --model">{title}</div>
        <div className="configurator-slideCopy --model">{copy}</div>

        <hr className="configurator-slideRule" />
        <input
          name="name"
          type="text"
          placeholder="Type a name here"
          className="configurator-input"
          ref={textInput}
          onKeyUp={() => {
            setTextActive(textInput.current.value);
          }}
        />
        <div className="configurator-buttonCon">
          {back ? (
            <Button
              text={back}
              left={true}
              passClick={() => {
                contextReact.updater("swiperStep", swiper.activeIndex + 1);
                closePopUp();
              }}
            />
          ) : (
            <div></div>
          )}
 
        </div>
      </div>
    </div>
  );
}
