import { useContext, useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { ContextData } from "data/Context";
import { useHistory } from "react-router-dom";
import { Button } from "components";
import { ReactComponent as LogoBackground } from "svg/TextPatternBG.svg";
import { EditModelPopup } from "components";
import { clone, Portal } from "hooks";
import { useWindowDimensions } from "hooks";
import * as preFilter from "leo-profanity";

interface IProps {
  title: string;
  copy: string;
  forward?: string;
  back?: string;
  namerPopup?: any;
  swiper: any;
  modelModeTarget?: number;
  modelModeFunction?: any;
  modelModeFunctionBack?: any;
  children?: any;
  active?: any;
}

var filter: any = preFilter;

export default function ConfiguratorSlideNamer({
  title,
  copy,
  forward,
  back,
  namerPopup,
  modelModeTarget,
  modelModeFunction,
  modelModeFunctionBack,
  children,
  active,
  swiper,
}: IProps) {
  const contextReact = useContext(ContextData);
  let history: any = useHistory();
  const textInput = useRef(null);
  const [textActive, setTextActive] = useState(false);
  const [popup, setPopup] = useState(null);
  const { height, width }: any = useWindowDimensions();
  // console.log(contextReact.currentGooGoo.name);

  return (
    <>
      <div className="configurator-slide --namer">
        {children}
        <div className="configurator-flex">
          <div>
            <h3 className="configurator-slideTitle">{title}</h3>
            <div className="configurator-slideCopy">{copy}</div>
          </div>
        </div>
        <hr className="configurator-slideRule" />
        <input
          name="name"
          type="text"
          autoComplete="off"
          placeholder="Type a name here"
          maxLength={20}
          className="configurator-input"
          style={{
            borderColor: filter.check(textActive) ? "#E31B23" : "",
          }}
          defaultValue={
            contextReact.currentGooGoo.name &&
            !textActive &&
            contextReact.swiperStep === 4
              ? contextReact.currentGooGoo.name.replace(/[|&;$%@"'<>()+,]/g, "")
              : undefined
          }
          ref={textInput}
          onFocus={() => {
            setTextActive(
              textInput.current.value.replace(/[|&;$%@"'<>()+,]/g, "")
            );
          }}
          onKeyUp={() => {
            setTextActive(
              textInput.current.value.replace(/[|&;$%@"'<>()+,]/g, "")
            );
          }}
        />

        <div className="configurator-addonCon">
          <LogoBackground className="configurator-addonBG" />
          <div>
            <h3 className="configurator-addonTitle">Make it fancy!</h3>
            <div className="configurator-addonCopy">
              Add an extra special finishing touch to the outside of your Goo
              Goo.
            </div>

            {contextReact.currentGooGoo.addOns.length === 0 ? (
              <Button
                text="let’s do it!"
                passClick={() => {
                  setPopup(true);
                  contextReact.createTempGooGoo("currentGooGoo");
                }}
              />
            ) : (
              <div
                className="configurator-addonText"
                onClick={() => {
                  setPopup(true);
                  contextReact.createTempGooGoo("currentGooGoo");
                }}
              >
                Need to edit your footer add-on?
              </div>
            )}
          </div>
        </div>

        <div className="configurator-buttonCon --inLine">
          {back ? (
            <Button
              text={back}
              left={true}
              passClick={() => {
                if (modelModeFunctionBack) {
                  modelModeFunctionBack();
                } else {
                  swiper.slidePrev();
                  contextReact.updater("swiperStep", swiper.activeIndex + 1);
                }
              }}
            />
          ) : (
            <div></div>
          )}

          <Button
            text={forward}
            disabled={
              (textActive && !filter.check(textActive)) ||
              (contextReact.currentGooGoo.name && !filter.check(textActive))
                ? false
                : true
            }
            passClick={() => {
              contextReact.nameGooGoo(
                textActive ? textActive : contextReact.currentGooGoo.name
              );
              // console.log(contextReact.currentGooGoo);
              // console.log(contextReact.cart);

              setTimeout(() => {
                contextReact.addGooGooToCart();
              }, 10);
              setTimeout(() => {
                history.push("/Cart");
              }, 100);
            }}
          />
        </div>

        <Portal id={`buttoncon`}>
          <div
            className="configurator-buttonCon --fixed"
            style={{
              transform: `translateX(-${100 * (contextReact.swiperStep - 1)}%)`,
            }}
          >
            {back ? (
              <Button
                text={back}
                left={true}
                passClick={() => {
                  if (modelModeFunctionBack) {
                    modelModeFunctionBack();
                  } else {
                    swiper.slidePrev();
                    contextReact.updater("swiperStep", swiper.activeIndex + 1);
                  }
                }}
              />
            ) : (
              <div></div>
            )}

            <Button
              text={forward}
              disabled={
                textActive || contextReact.currentGooGoo.name ? false : true
              }
              passClick={() => {
                contextReact.nameGooGoo(
                  textActive ? textActive : contextReact.currentGooGoo.name
                );
                // console.log(contextReact.currentGooGoo);
                // console.log(contextReact.cart);

                setTimeout(() => {
                  contextReact.addGooGooToCart();
                }, 10);
                setTimeout(() => {
                  history.push("/Cart");
                }, 100);
              }}
            />
          </div>
        </Portal>
      </div>

      <Portal id="modal">
        <div className="cart-page --addOns">
          <EditModelPopup
            active={popup ? true : false}
            items={contextReact.ingredients.addOns}
            step={
              <svg
                width="38"
                height="37"
                viewBox="0 0 38 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.0489 0.927049C18.3483 0.00573802 19.6517 0.00574017 19.9511 0.927051L23.7148 12.5106C23.8487 12.9227 24.2326 13.2016 24.6659 13.2016H36.8456C37.8143 13.2016 38.2171 14.4412 37.4333 15.0106L27.5798 22.1697C27.2293 22.4243 27.0826 22.8757 27.2165 23.2877L30.9802 34.8713C31.2796 35.7926 30.2251 36.5588 29.4414 35.9894L19.5878 28.8303C19.2373 28.5757 18.7627 28.5757 18.4122 28.8303L8.55862 35.9894C7.77491 36.5588 6.72043 35.7926 7.01978 34.8713L10.7835 23.2877C10.9174 22.8757 10.7707 22.4243 10.4202 22.1697L0.566653 15.0106C-0.217061 14.4412 0.185717 13.2016 1.15444 13.2016H13.3342C13.7674 13.2016 14.1513 12.9227 14.2852 12.5106L18.0489 0.927049Z"
                  fill="white"
                />
              </svg>
            }
            index={null}
            title="Footer Add-On"
            IngredientType="addOns"
            copy={
              <p>
                Select an extra layer of flavor to make your
                {width < 809 ? <br /> : ""}Goo Goo even better.
              </p>
            }
            addOnModel={true}
            stateFunction={(i: number) => {
              setPopup(false);
            }}
          />
        </div>
      </Portal>
    </>
  );
}
