import React from "react";
import { Seal } from "components";

export default function HeroSteps({ step, children }: any) {
  return (
    <div className="homeHero-textCon --Small">
      <div className="homeHero-stepText">STEP</div>
      <Seal>{step}</Seal>
      <hr className="homeHero-rule" />
      <div className="homeHero-stepTitle">{children}</div>
    </div>
  );
}
