import React, { useState, useEffect } from "react";
import { Button } from "components";

interface IProps {
  title: string;
  copy?: string;
  forward: string;
  back?: string;
  forwardFunction: any;
  backFunction?: any;
  passedState: boolean;
  passedStateFunction?: any;
}

export default function NoticePopUp({
  title,
  copy,
  forward,
  back,
  forwardFunction,
  backFunction,
  passedState,
  passedStateFunction,
}: IProps) {
  return (
    <div
      className="popup-bg"
      style={{
        display: passedState ? "flex" : "none",
      }}
    >
      <div className="popup-con">
        <div className="popup-title">{title}</div>
        {copy ? <div className="popup-copy">{copy}</div> : null}
        <div className="popup-buttonCon">
          {back ? (
            <Button
              left={true}
              text={back}
              passClick={() => {
                backFunction();
              }}
            />
          ) : (
            <div></div>
          )}
          <Button
            text={forward}
            passClick={() => {
              forwardFunction();
            }}
          />
        </div>
      </div>
    </div>
  );
}
