import "font.css";
import "swiper/swiper.scss";
import "styles/index.scss";
import ContextCon from "data/Context";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { TransitionGroup, Transition } from "react-transition-group";
import { Header } from "components";
import { ViewportNormalize } from "hooks";
import { Home, Configurator, Cart, Checkout } from "pages";
import React, { useEffect, useRef, useContext } from "react";
import { play, exit } from "transitions/play";
import RegisterReader from "pages/RegisterReader";
import DebugStripe from "pages/DebugStripe";
import { ContextData } from "data/Context";
/**
 * this 2nd react function is needed to use the useLocation hook.
 * it cannot be used in the same component as the router
 */

const InnerApp = () => {
  const contextReact = useContext(ContextData);
  let history = useHistory();

  useEffect(() => {
    ViewportNormalize();

    const userAgent = navigator.userAgent;

    (function () {
      const idleDurationSecs = 60 * 5; // X number of seconds
      const redirectUrl = "./"; // Redirect idle users to this URL
      let idleTimeout: any; // variable to hold the timeout, do not modify

      const resetIdleTimeout = function () {
        // Clears the existing timeout
        if (idleTimeout) clearTimeout(idleTimeout);

        // Set a new idle timeout to load the redirectUrl after idleDurationSecs
        idleTimeout = setTimeout(() => {
          if (window.location.pathname === "/Checkout/Card") {
          } else {
            if (window.location.pathname !== "/") {
              contextReact.flushCart();
              contextReact.updater("swiperStep", 1);
              setTimeout(() => {
                contextReact.updateCartCount();
              }, 100);
            }
            history.push("/");
          }
        }, idleDurationSecs * 1000);
      };

      // Init on page load
      resetIdleTimeout();

      // Reset the idle timeout on any of the events listed below
      ["click", "touchstart", "mousemove"].forEach((evt) =>
        document.addEventListener(evt, resetIdleTimeout, false)
      );
    })();
  }, []);
  const nodeRef = React.useRef(null);
  const lastRef: any = null;
  return (
    <>
      <Switch>
        <Route path={["/registerreader", "/Checkout/cancel"]}></Route>

        <Route path="/">
          <Header />
        </Route>
      </Switch>
      <Route
        render={({ location }) => {
          const { pathname, key }: any = location;
          return (
            <Switch location={location}>
              <Route exact path="/">
                <div ref={nodeRef}>
                  <Home />
                </div>
              </Route>
              <Route path="/Configurator">
                <div className="Configurator" ref={nodeRef}>
                  <Configurator />
                </div>
              </Route>
              <Route path="/Cart">
                <div className="cart" ref={nodeRef}>
                  <Cart />
                </div>
              </Route>
              <Route path="/Checkout">
                <div ref={nodeRef}>
                  <Checkout />
                </div>
              </Route>
              <Route path="/RegisterReader">
                <RegisterReader />
              </Route>
              <Route path="/DebugStripe">
                <DebugStripe />
              </Route>
            </Switch>
          );
        }}
      />
    </>
  );
};

function App() {
  return (
    <ContextCon>
      <Router>
        <InnerApp />
      </Router>
    </ContextCon>
  );
}

export default App;
