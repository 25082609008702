import { ContextData } from "data/Context";
import { useContext } from "react";
import { ConfiguratorSlide } from "components";

export default function EditModelPopup({
  active,
  step,
  title,
  IngredientType,
  copy,
  items,
  index,
  stateFunction,
  addOnModel,
}: any) {
  const contextReact: any = useContext(ContextData);
  return (
    <div
      className="cart-modelBG configurator-backgroundModel"
      style={{
        pointerEvents: active ? "all" : "none",
        opacity: active ? "1" : "0",
      }}
    >
      <ConfiguratorSlide
        step={step}
        title={title}
        IngredientType={IngredientType}
        copy={copy}
        items={items}
        swiper={null}
        forward="Save"
        back="Cancel"
        modelModeTarget={index ? index : undefined}
        addOnModel={addOnModel}
        modelModeFunction={() => {
          stateFunction(0);
          if (addOnModel) {
            contextReact.resetHistory();
          } else {
            contextReact.resetConfiguratorCart();
          }
        }}
        modelModeFunctionBack={() => {
          stateFunction(0);
          if (addOnModel) {
            contextReact.restoreTempGooGoo("currentGooGoo");
          } else {
            contextReact.restoreTempGooGoo(index);
          }
        }}
        active={active}
      >
        <div
          className="cart-modelX"
          onClick={() => {
            stateFunction(0);
            if (addOnModel) {
              contextReact.restoreTempGooGoo("currentGooGoo");
            } else {
              contextReact.restoreTempGooGoo(index);
            }
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="18"
              y="15.0703"
              width="3.55211"
              height="21.3127"
              rx="1.77606"
              transform="rotate(135 18 15.0703)"
              fill="#7299C6"
            />
            <rect
              x="2.51172"
              y="17.9995"
              width="3.55211"
              height="21.3127"
              rx="1.77606"
              transform="rotate(-135 2.51172 17.9995)"
              fill="#7299C6"
            />
          </svg>
        </div>
      </ConfiguratorSlide>
    </div>
  );
}
