import React from "react";
import Button from "components/Button";
import GGCStoreFront from "services/ggc-storefront-api";
import { Reader } from "@stripe/terminal-js";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";

interface IProps {}
interface IState {
  readers: Reader[];
  readerID: string;
}

export default class RegisterReader extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      readers: [],
      readerID: GGCStoreFront.getReaderSerialNumber(),
    };

    this.discoverReaders = this.discoverReaders.bind(this);
    this.connectReader = this.connectReader.bind(this);
    this.disconnectReader = this.disconnectReader.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.discoverReaders();
    }, 2000);
  }

  discoverReaders() {
    console.log("Discovering readers");
    this.setState({
      readers: [],
    });

    GGCStoreFront.getInstance().initializeStripe(() => {
      GGCStoreFront.getInstance()
        .discoverCardReaders()
        .then(() => {
          this.setState({
            readers: GGCStoreFront.getInstance().readers,
          });
        });
    });
  }

  connectReader(readerID: string) {
    console.log("Connecting to reader");
    GGCStoreFront.setReaderSerialNumber(readerID);
    GGCStoreFront.getInstance().initializeStripe(() => {
      GGCStoreFront.getInstance()
        .connectCardReader()
        .then(() => {
          this.discoverReaders();
        });
    });
  }

  disconnectReader(readerID: string) {
    console.log("Disconnecting reader");
    GGCStoreFront.setReaderSerialNumber("");
    GGCStoreFront.getInstance()
      .disconnectCardReader()
      .then(() => {
        this.discoverReaders();
      });
  }

  render() {
    return (
      <>
        <br />
        <br />
        <p className="checkout-copy --smaller" style={{ margin: "auto" }}>
          Click the connect button to connect to the proper reader. Then click
          the refresh page button to confirm that the reader connected. If
          successful the button will now say Disconnect.
        </p>
        <br />

        <div className="configurator-buttonCon --inLine checkout-input">
          <Button
            left={true}
            text={"Refresh Readers"}
            passClick={() => {
              this.discoverReaders();
              // window.location.reload();
            }}
          />
          <Button
            left={false}
            text={"Refresh Page"}
            passClick={() => {
              // this.discoverReaders();
              window.location.reload();
            }}
          />
        </div>

        <table>
          <tr>
            <td></td>
            <td width={200}>Serial Number</td>
            <td width={200}>Label</td>
            <td width={200}>Location</td>
            <td width={200}>Status</td>
          </tr>

          {this.state.readers?.map((reader: Reader, index: number) => {
            let connected = this.state.readerID === reader.serial_number;
            return (
              <tr key={`reader_${index}`}>
                <td>
                  <Button
                    left={true}
                    text={connected ? "Disconnect" : "Connect"}
                    passClick={() => {
                      if (connected === false) {
                        this.connectReader(reader.serial_number);
                      } else {
                        this.disconnectReader(reader.serial_number);
                      }
                    }}
                  />
                </td>
                <td>{reader.serial_number}</td>
                <td>{reader.label}</td>
                <td>{reader.location}</td>
                <td>{reader.status}</td>
              </tr>
            );
          })}
        </table>
        <br />

        <div className="checkout-model-shopify">
          <div className="checkout-model-inner"></div>
        </div>
        <div className="configurator-buttonCon --inLine checkout-input">
          <div></div>
          <Link to="/">
            <Button left={false} text={"go to home page"} />
          </Link>
          <div></div>
        </div>
        <br />
        <br />
        <br />
        <p className="checkout-copy --smaller" style={{ margin: "auto" }}>
          link to documentation.
        </p>
        <div className="configurator-buttonCon --inLine checkout-input">
          <div></div>
          <QRCode
            style={{ margin: "auto" }}
            value={
              "https://docs.google.com/document/d/1DjPyY8UaV_nx-ccqgRnK-Spxik5Ww1h7h6JV6-pmGdQ/edit?usp=sharing"
            }
          />
          <div></div>
        </div>
      </>
    );
  }
}
