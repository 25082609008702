import { ContextData } from "data/Context";
import { useContext, useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Link,
} from "react-router-dom";

export default function GooGooPreview() {
  const contextReact: any = useContext(ContextData);

  useEffect(() => {
    contextReact.ingredients.shells.forEach((picture: any) => {
      if (window.devicePixelRatio < 1.5) {
        new Image().src = `images/big-icons/1x/GG_Ingredients_${picture.src}.png`;
      } else {
        new Image().src = `images/big-icons/2x/GG_Ingredients_${picture.src}.png`;
      }
    });
    contextReact.ingredients.sweets.forEach((picture: any) => {
      if (window.devicePixelRatio < 1.5) {
        new Image().src = `images/big-icons/1x/GG_Ingredients_${picture.src}.png`;
      } else {
        new Image().src = `images/big-icons/2x/GG_Ingredients_${picture.src}.png`;
      }
    });
    contextReact.ingredients.addIns.forEach((picture: any) => {
      if (window.devicePixelRatio < 1.5) {
        new Image().src = `images/big-icons/1x/GG_Ingredients_${picture.src}.png`;
      } else {
        new Image().src = `images/big-icons/2x/GG_Ingredients_${picture.src}.png`;
      }
    });
  });

  return (
    <div className="preview-con">
      <svg
        viewBox="0 0 700 350"
        preserveAspectRatio="xMinYMin meet"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          opacity:
            !contextReact.currentGooGoo.shell[0] &&
            !contextReact.currentGooGoo.sweets[0] &&
            !contextReact.currentGooGoo.addIns[0]
              ? "1"
              : "0",
        }}
      >
        <path
          d="M160.385 350H128.187V345H123.187H110C109.473 345 108.975 344.92 108.51 344.776L106.004 343.996L105.224 341.49C105.08 341.025 105 340.527 105 340V327.308V322.308H100V291.692H105V286.692V261.308V256.308H100V225.692H105V220.692V195.308V190.308H100V159.692H105V154.692V129.308V124.308H100V93.6923H105V88.6923V63.3077V58.3077H100V27.6923H105V22.6923V10C105 9.47332 105.08 8.9746 105.224 8.51022L106.004 6.00434L108.51 5.22431C108.975 5.07975 109.473 5 110 5H123.187H128.187V0H160.385V5H165.385H191.758H196.758V0H228.956V5H233.956H260.33H265.33V0H297.527V5H302.527H328.901H333.901V0H366.099V5H371.099H397.473H402.473V0H434.67V5H439.67H466.044H471.044V0H503.242V5H508.242H534.615H539.615V0H571.813V5H576.813H590C590.527 5 591.025 5.07975 591.49 5.22431L593.996 6.00434L594.776 8.51022C594.92 8.9746 595 9.47332 595 10V22.6923V27.6923H600V58.3077H595V63.3077V88.6923V93.6923H600V124.308H595V129.308V154.692V159.692H600V190.308H595V195.308V220.692V225.692H600V256.308H595V261.308V286.692V291.692H600V322.308H595V327.308V340C595 340.527 594.92 341.025 594.776 341.49L593.996 343.996L591.49 344.776C591.025 344.92 590.527 345 590 345H576.813H571.813V350H539.615V345H534.615H508.242H503.242V350H471.044V345H466.044H439.67H434.67V350H402.473V345H397.473H371.099H366.099V350H333.901V345H328.901H302.527H297.527V350H265.33V345H260.33H233.956H228.956V350H196.758V345H191.758H165.385H160.385V350Z"
          stroke="#F8F5E2"
          strokeWidth="10"
          strokeMiterlimit="1.41421"
          strokeLinecap="square"
          strokeDasharray="25 40"
        />
        <rect y="83" width="700" height="185" fill="#FFFDEF" />
        <path
          d="M77.8333 163.084C72.6841 163.351 67.5373 162.515 62.7373 160.632C57.9372 158.75 53.5946 155.864 50 152.167L58 145.459C63.3636 150.642 70.3906 153.757 77.8333 154.251C84.4583 154.251 87.75 152.667 87.75 150.084C87.75 145.209 79.9166 144.001 71.9166 141.751C60.0416 138.417 51.0833 134.292 51.0833 123.709C51.0833 110.626 63.2083 104.167 78.5 104.167C83.0385 103.864 87.5916 104.478 91.8871 105.975C96.1826 107.471 100.132 109.818 103.5 112.876L95.7916 119.209C90.9465 115.365 85.0119 113.149 78.8333 112.876C71.7917 112.876 69.0416 114.834 69.0416 117.417C69.0416 121.584 75.2083 121.876 85.125 124.751C97.4166 128.251 106.625 133.084 106.625 144.001C106.667 155.626 95.75 163.084 77.8333 163.084Z"
          fill="#F8F5E2"
        />
        <path
          d="M145.292 115.875V161.292H125V115.875H108.333V115.167L112.5 105.917H158.333L162.5 115.167V115.875H145.292Z"
          fill="#F8F5E2"
        />
        <path
          d="M202.125 161.291L198.25 151.208H176.708L172.958 161.291H159.125V160.583L182.417 105.833H202.917L224.125 160.583V161.291H202.125ZM187.667 121.125L180.042 141.666H194.625L187.667 121.125Z"
          fill="#F8F5E2"
        />
        <path
          d="M271.25 161.291L256.792 142.458C256.409 141.844 255.865 141.347 255.217 141.023C254.57 140.7 253.846 140.562 253.125 140.625V161.458H233V105.833H262.167C279.792 105.833 287.375 110.625 287.375 120.875C287.307 124.639 285.995 128.275 283.643 131.214C281.292 134.154 278.032 136.232 274.375 137.125L293.5 160.583V161.291H271.25ZM257.917 114.25H253.125V133.583C262.5 133.583 266.542 129.708 266.542 123.166C266.624 121.999 266.46 120.827 266.06 119.727C265.66 118.628 265.033 117.625 264.219 116.783C263.405 115.942 262.423 115.282 261.338 114.845C260.252 114.409 259.086 114.206 257.917 114.25Z"
          fill="#F8F5E2"
        />
        <path
          d="M331.292 115.875V161.292H311.001V115.875H294.334V115.167L298.501 105.917H344.334L348.501 115.167V115.875H331.292Z"
          fill="#F8F5E2"
        />
        <path
          d="M412.708 141.333V161.291H392.584V141.333L370.208 106.583V105.833H394.667L407.167 128.958L419.667 105.833H434.208V106.583L412.708 141.333Z"
          fill="#F8F5E2"
        />
        <path
          d="M469.749 163.083C450.916 163.083 435.791 151.458 435.791 133.5C435.791 115.542 450.916 104 469.749 104C488.583 104 503.708 115.708 503.708 133.5C503.708 151.292 488.583 163.083 469.749 163.083ZM469.749 111.167C462.874 111.167 457.249 119.25 457.249 131.625C457.249 148.292 462.583 155.25 469.749 155.25C476.916 155.25 482.041 148.458 482.041 131.625C482.041 119.25 476.624 111.167 469.749 111.167Z"
          fill="#F8F5E2"
        />
        <path
          d="M543.791 163.708C526.916 163.708 514.624 154.75 514.624 138.166V105.833H534.749V140.541C534.749 147 536.916 151.541 543.874 151.541C550.832 151.541 553.249 147 553.249 140.541V105.833H573.374V138.166C573.249 154.75 560.707 163.708 543.791 163.708Z"
          fill="#F8F5E2"
        />
        <path
          d="M627.874 161.291L613.457 142.458C613.052 141.856 612.497 141.37 611.847 141.049C611.196 140.727 610.473 140.581 609.749 140.625V161.458H589.624V105.833H618.791C636.416 105.833 643.791 110.625 643.791 120.875C643.725 124.633 642.419 128.265 640.076 131.204C637.732 134.143 634.482 136.225 630.832 137.125L649.916 160.583V161.291H627.874ZM614.624 114.25H609.832V133.583C619.207 133.583 623.249 129.708 623.249 123.166C623.332 121.999 623.168 120.827 622.768 119.727C622.367 118.628 621.74 117.625 620.926 116.783C620.113 115.942 619.131 115.282 618.045 114.845C616.959 114.409 615.794 114.206 614.624 114.25Z"
          fill="#F8F5E2"
        />
        <path
          d="M91.25 245.042C68.375 245.042 50 231.25 50 210.667C50 190.084 68.375 176.375 91.25 176.375C101.404 176.297 111.302 179.562 119.417 185.667L110.333 194C105.438 189.82 99.2676 187.425 92.8333 187.209C82.25 187.209 75.4583 194.167 75.4583 210.584C75.4583 227 82.25 233.75 92.8333 233.75C99.2435 233.599 105.413 231.278 110.333 227.167L119.417 235.5C111.36 241.743 101.442 245.103 91.25 245.042Z"
          fill="#F8F5E2"
        />
        <path
          d="M174.624 243L157.958 221.125C157.51 220.44 156.888 219.886 156.156 219.52C155.424 219.154 154.608 218.989 153.791 219.041V243H130.416V178.583H164.208C184.708 178.583 193.374 184.166 193.374 196.041C193.374 205.75 186.958 211.958 178.249 214.958L200.458 242.208V243H174.624ZM159.166 188.375H153.541V210.833C164.458 210.833 169.166 206.333 169.166 198.75C169.241 197.399 169.036 196.048 168.565 194.78C168.094 193.513 167.366 192.356 166.428 191.382C165.489 190.408 164.36 189.639 163.11 189.121C161.861 188.604 160.518 188.35 159.166 188.375Z"
          fill="#F8F5E2"
        />
        <path
          d="M260.458 243H210.458V178.583H256.624L261.416 189.375V190.166H233.833V204.625H261.416V215.875H233.833V231.25H264.999V232.041L260.458 243Z"
          fill="#F8F5E2"
        />
        <path
          d="M318.791 243L314.291 231.333H289.291L284.874 243H268.791V242.208L295.874 178.583H319.666L344.333 242.208V243H318.791ZM302.124 196.333L293.208 220.25H310.458L302.124 196.333Z"
          fill="#F8F5E2"
        />
        <path
          d="M383.875 190.166V243H360.458V190.166H340.958V189.375L345.75 178.583H398.792L403.625 189.375V190.166H383.875Z"
          fill="#F8F5E2"
        />
        <path d="M412.917 243V178.583H436.292V243H412.917Z" fill="#F8F5E2" />
        <path
          d="M488.708 245.042C466.833 245.042 449.25 231.542 449.25 210.667C449.25 189.792 466.833 176.375 488.708 176.375C510.583 176.375 528.166 189.958 528.166 210.667C528.166 231.375 510.458 245.042 488.708 245.042ZM488.708 184.667C480.708 184.667 474.416 194.083 474.416 208.458C474.416 228.042 480.625 235.917 488.708 235.917C496.791 235.917 502.999 228.042 502.999 208.458C502.999 194.167 496.708 184.75 488.708 184.75V184.667Z"
          fill="#F8F5E2"
        />
        <path
          d="M597.084 243L555.709 210.041V243H541.042V178.583H554L596.5 212.833V178.583H611.584V243H597.084Z"
          fill="#F8F5E2"
        />
        <path
          d="M643.291 217.416H632.291L625.708 186.25V178.583H650V186.25L643.291 217.416ZM637.583 245.041C634.477 245.008 631.51 243.752 629.326 241.544C627.141 239.336 625.916 236.355 625.916 233.25C625.948 230.154 627.184 227.192 629.361 224.991C631.539 222.79 634.488 221.523 637.583 221.458C640.706 221.51 643.688 222.766 645.908 224.963C648.128 227.159 649.414 230.128 649.5 233.25C649.456 236.385 648.183 239.378 645.954 241.583C643.725 243.789 640.719 245.031 637.583 245.041Z"
          fill="#F8F5E2"
        />
      </svg>
      <GooGooSliceShell
        name={
          contextReact.currentGooGoo.shell[0]
            ? contextReact.currentGooGoo.shell[0].title
            : null
        }
        src={
          contextReact.currentGooGoo.shell[0]
            ? contextReact.currentGooGoo.shell[0].src
            : null
        }
        active={contextReact.currentGooGoo.shell[0] ? true : null}
      />
      <GooGooSlice
        name={
          contextReact.currentGooGoo.addIns[2]
            ? contextReact.currentGooGoo.addIns[2].title
            : null
        }
        src={
          contextReact.currentGooGoo.addIns[2]
            ? contextReact.currentGooGoo.addIns[2].src
            : null
        }
        active={contextReact.currentGooGoo.addIns[2] ? true : null}
        zIndex={6}
      />
      <GooGooSlice
        name={
          contextReact.currentGooGoo.addIns[1]
            ? contextReact.currentGooGoo.addIns[1].title
            : null
        }
        src={
          contextReact.currentGooGoo.addIns[1]
            ? contextReact.currentGooGoo.addIns[1].src
            : null
        }
        active={contextReact.currentGooGoo.addIns[1] ? true : null}
        zIndex={5}
        flip={true}
      />
      <GooGooSlice
        name={
          contextReact.currentGooGoo.addIns[0]
            ? contextReact.currentGooGoo.addIns[0].title
            : null
        }
        src={
          contextReact.currentGooGoo.addIns[0]
            ? contextReact.currentGooGoo.addIns[0].src
            : null
        }
        active={contextReact.currentGooGoo.addIns[0] ? true : null}
        zIndex={4}
      />
      <GooGooSlice
        name={
          contextReact.currentGooGoo.sweets[1]
            ? contextReact.currentGooGoo.sweets[1].title
            : null
        }
        src={
          contextReact.currentGooGoo.sweets[1]
            ? contextReact.currentGooGoo.sweets[1].src
            : null
        }
        active={contextReact.currentGooGoo.sweets[1] ? true : null}
        zIndex={3}
        flip={true}
      />
      <GooGooSlice
        name={
          contextReact.currentGooGoo.sweets[0]
            ? contextReact.currentGooGoo.sweets[0].title
            : null
        }
        src={
          contextReact.currentGooGoo.sweets[0]
            ? contextReact.currentGooGoo.sweets[0].src
            : null
        }
        active={contextReact.currentGooGoo.sweets[0] ? true : null}
        zIndex={2}
      />
      <GooGooSliceShellBottom
        name={
          contextReact.currentGooGoo.shell[0]
            ? contextReact.currentGooGoo.shell[0].title
            : null
        }
        src={
          contextReact.currentGooGoo.shell[0]
            ? contextReact.currentGooGoo.shell[0].src
            : null
        }
        active={contextReact.currentGooGoo.shell[0] ? true : null}
      />
    </div>
  );
}

function GooGooSlice({ name, src, active, zIndex, flip }: any) {
  return (
    <div
      className="slice-con"
      style={{
        zIndex: zIndex,
        height: active ? "" : "0px",
        opacity: active ? "1" : "0",
      }}
    >
      <div className="slice-imgCon">
        <img
          style={{ transform: flip ? "scaleX(-1) translateY(-50%)" : null }}
          srcSet={
            "images/big-icons/1x/GG_Ingredients_" +
            src +
            ".png, " +
            "images/big-icons/2x/GG_Ingredients_" +
            src +
            ".png 2x"
          }
          src={"images/big-icons/1x/GG_Ingredients_" + src + ".png"}
          alt=""
          className="slice-img"
        />
      </div>
      <div className="slice-text">
        <div className="slice-title">
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
}

function GooGooSliceShell({ name, active, src }: any) {
  return (
    <>
      <div
        className="slice-con --top --over"
        style={{
          height: active ? "" : "0px",
          opacity: active ? "1" : "0",
          marginBottom: active ? "-95px" : 0,
        }}
      >
        <div className="slice-imgCon">
          <img
            srcSet={
              "images/big-icons/1x/GG_Ingredients_" +
              src +
              "_Top.png, " +
              "images/big-icons/2x/GG_Ingredients_" +
              src +
              "_Top.png 2x"
            }
            src={"images/big-icons/1x/GG_Ingredients_" + src + "_Top.png"}
            alt=""
            className="slice-img"
          />
        </div>
        <div className="slice-text"></div>
      </div>
      <div
        className="slice-con --top"
        style={{
          height: active ? "" : "0px",
          opacity: active ? "1" : "0",
        }}
      >
        <div className="slice-imgCon">
          <img
            srcSet={
              "images/big-icons/1x/GG_Ingredients_" +
              src +
              ".png, " +
              "images/big-icons/2x/GG_Ingredients_" +
              src +
              ".png 2x"
            }
            src={"images/big-icons/1x/GG_Ingredients_" + src + ".png"}
            alt=""
            className="slice-img"
          />
        </div>
        <div className="slice-text"></div>
      </div>
    </>
  );
}

function GooGooSliceShellBottom({ name, active, src }: any) {
  return (
    <div
      className="slice-con --bottom"
      style={{
        height: active ? "" : "0px",
        opacity: active ? "1" : "0",
      }}
    >
      <div className="slice-imgCon">
        <img
          src={"images/big-icons/1x/GG_Ingredients_" + src + "_Bottom" + ".png"}
          srcSet={
            "images/big-icons/1x/GG_Ingredients_" +
            src +
            "_Bottom" +
            ".png, " +
            "images/big-icons/2x/GG_Ingredients_" +
            src +
            "_Bottom" +
            ".png 2x"
          }
          alt=""
          className="slice-img"
        />
      </div>
      <div className="slice-text">
        <div className="slice-title">
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
}
