import React, { useEffect, useContext, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { ContextData } from "data/Context";
import { useWindowDimensions } from "hooks";
import {
  ConfiguratorSlide,
  NamerPopup,
  GooGooPreview,
  ConfiguratorSlideNamer,
} from "components";

SwiperCore.use([Navigation, Pagination]);

let contextReact: any;

export default function Configurator() {
  contextReact = useContext(ContextData);

  const [popupActive, setPopupActive] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const { width }: any = useWindowDimensions();
  const [, updateState]: any = useState();
  const forceUpdate: any = React.useCallback(() => updateState({}), []);

  let swiperTitleLocation: any;

  useEffect(() => {
    if (swiper) {
      // if (contextReact.swiperStep === 4) {
      //   setPopupActive(true);
      // }
      if (contextReact.swiperStep !== 1) {
        console.log("running");
        console.log(contextReact.swiperStep);
        swiper.slideTo(contextReact.swiperStep - 1, 22);
      }
    }
  }, [swiper]);

  function eventFunction() {
    mobileHeightSetter(width);
  }

  useEffect(() => {
    window.addEventListener("scroll", eventFunction);

    return function cleanupListener() {
      window.removeEventListener("scroll", eventFunction);
    };
  });

  return (
    <div className="configurator-con" style={{}}>
      <div className="headerProgress">
        <div className="headerProgress-titleCon">
          <div className="headerProgress-thirdsCon">
            <div className="headerProgress-stepCount">1</div>
            <div
              className={
                "headerProgress-title" +
                (contextReact.swiperStep === 1 ? " --active" : "")
              }
              style={{
                width: `${
                  width < 809
                    ? contextReact.swiperStep === 1
                      ? "max-content"
                      : "0"
                    : ""
                }`,
                opacity: `${
                  width < 809 ? (contextReact.swiperStep === 1 ? "1" : "0") : ""
                }`,
              }}
            >
              Chocolate Shell
            </div>
          </div>
          <div
            className="headerProgress-thirdsCon"
            style={{
              transition: "color .3s",
              color: `${contextReact.swiperStep > 1 ? "inherit" : "#E8E6DF"}`,
            }}
          >
            <div
              className="headerProgress-stepCount"
              style={{
                transition: "background .3s",
                background: `${
                  contextReact.swiperStep > 1 ? "#7299c6" : "#E8E6DF"
                }`,
              }}
            >
              2
            </div>
            <div
              className={
                "headerProgress-title" +
                (contextReact.swiperStep === 2 ? " --active" : "")
              }
              style={{
                width: `${
                  width < 809
                    ? contextReact.swiperStep === 2
                      ? "max-content"
                      : "0"
                    : ""
                }`,
                opacity: `${
                  width < 809 ? (contextReact.swiperStep === 2 ? "1" : "0") : ""
                }`,
              }}
            >
              Sweet Fillings
            </div>
          </div>
          <div
            className="headerProgress-thirdsCon"
            style={{
              transition: "color .3s",
              color: `${contextReact.swiperStep > 2 ? "inherit" : "#E8E6DF"}`,
            }}
          >
            <div
              className="headerProgress-stepCount"
              style={{
                transition: "background .3s",
                background: `${
                  contextReact.swiperStep > 2 ? "#7299c6" : "#E8E6DF"
                }`,
              }}
            >
              3
            </div>
            <div
              className={
                "headerProgress-title" +
                (contextReact.swiperStep === 3 ? " --active" : "")
              }
              style={{
                width: `${
                  width < 809
                    ? contextReact.swiperStep === 3
                      ? "max-content"
                      : "0"
                    : ""
                }`,
                opacity: `${
                  width < 809 ? (contextReact.swiperStep === 3 ? "1" : "0") : ""
                }`,
              }}
            >
              Add-Ins
            </div>
          </div>
          <div
            className="headerProgress-thirdsCon"
            style={{
              transition: "color .3s",
              color: `${contextReact.swiperStep > 3 ? "inherit" : "#E8E6DF"}`,
            }}
          >
            <div
              className="headerProgress-stepCount"
              style={{
                transition: "background .3s",
                background: `${
                  contextReact.swiperStep > 3 ? "#7299c6" : "#E8E6DF"
                }`,
              }}
            >
              4
            </div>
            <div
              className={
                "headerProgress-title" +
                (contextReact.swiperStep === 4 ? " --active" : "")
              }
              style={{
                width: `${
                  width < 809
                    ? contextReact.swiperStep === 4
                      ? "max-content"
                      : "0"
                    : ""
                }`,
                opacity: `${
                  width < 809 ? (contextReact.swiperStep === 4 ? "1" : "0") : ""
                }`,
              }}
            >
              Finish
            </div>
          </div>
        </div>
        <div className="headerProgress-bar">
          <div
            className="headerProgress-barInner"
            style={{
              width: `${
                width > 809
                  ? contextReact.swiperStep * 25 + "%"
                  : document.querySelector(".headerProgress-title.--active")
                  ? document
                      .querySelector(".headerProgress-title.--active")
                      .getBoundingClientRect().right -
                    document
                      .querySelector(".headerProgress-bar")
                      .getBoundingClientRect().left +
                    "px"
                  : 0
              }`,
            }}
          ></div>
        </div>
      </div>
      <div className="configurator-productDisplay">
        <GooGooPreview />
      </div>
      <Swiper
        className="configurator"
        navigation
        spaceBetween={30}
        centeredSlides={true}
        allowTouchMove={false}
        slidesPerView={width > 809 ? 1.1 : 1.04}
        onSwiper={(swiperInstance) => {
          setSwiper(swiperInstance);

          // swiper = undefined;
          // swiper = swiperInstance;
        }}
        onSlideChange={(swiperInstance) => {
          window.scrollTo(0, 0);
          setTimeout(() => {
            forceUpdate();
          }, 10);
          setTimeout(() => {
            mobileHeightSetter(width);
          }, 100);
          setTimeout(() => {
            mobileHeightSetter(width);
          }, 1000);
        }}
      >
        <SwiperSlide>
          <ConfiguratorSlide
            step="1"
            title={`Choose ${width > 809 ? "your" : "a"} chocolate shell`}
            IngredientType="shell"
            copy=" Coat your candy bar in delicious chocolate. "
            items={contextReact.ingredients.shells}
            swiper={swiper}
            forward={width > 809 ? "Sweet Fillings" : "Sweets"}
            mobileNav={true}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ConfiguratorSlide
            step="2"
            title="Choose sweet fillings"
            IngredientType="sweets"
            copy=" Start building with two layers of gooey goodness. "
            items={contextReact.ingredients.sweets}
            swiper={swiper}
            forward="Add-ins"
            back={width > 809 ? "Chocolate Shell" : "Shell"}
            mobileNav={true}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ConfiguratorSlide
            step="3"
            title="Pick three add-ins"
            IngredientType="addIns"
            copy="Feel free to double or triple your favorite add-in. "
            items={contextReact.ingredients.addIns}
            swiper={swiper}
            forward="Finish order"
            back={width > 809 ? "Sweet Fillings" : "Sweets"}
            mobileNav={true}
            // namerPopup={() => {
            //   setPopupActive(true);
            // }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ConfiguratorSlideNamer
            title="Who’s this order for?"
            copy=" Add a name and we’ll call it out when the candy is complete. "
            forward="Add to cart"
            back="Add-ins"
            swiper={swiper}
          />
        </SwiperSlide>
      </Swiper>
      <NamerPopup
        active={popupActive}
        closePopUp={() => {
          setPopupActive(false);
        }}
        title="Give this Goo Goo a name"
        copy="Put a name on this creation and we’ll call it out when it’s ready for pickup."
        back="Cancel"
        forward="Save"
        swiper={swiper}
      />
    </div>
  );
}

function mobileHeightSetter(width: number) {
  if (width < 809 && document.querySelector(".swiper-slide-active")) {
    const target: HTMLElement = document.querySelector(".configurator-con");

    const allSlide: any =
      document.querySelectorAll<HTMLElement>(".configurator-con");

    allSlide.forEach((element: HTMLElement) => {
      element.style.height = "";
    });

    // console.log(
    //   document.querySelector<HTMLElement>(
    //     ".swiper-slide-active .configurator-slide"
    //   ).offsetHeight
    // );

    // console.log(
    //   document.querySelector<HTMLElement>(
    //     ".swiper-slide-active .configurator-slide"
    //   ).offsetHeight + 200
    // );
    // console.log();
    let offsetHeight = document.querySelector<HTMLElement>(
      ".swiper-slide-active .configurator-slide"
    )?.offsetHeight;

    if (offsetHeight === null || offsetHeight === undefined) {
      offsetHeight = 0;
    }

    if (target && target.style) {
      target.style.height = offsetHeight + 200 + "px";
    }
  }
}
