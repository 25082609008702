/**
 *
 * @param inObject any object, or array
 * @returns a deep copy of an object
 *
 */

export default function clone(inObject: any) {
  let outObject: any, value: any, key: any;

  if (typeof inObject !== "object" || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = clone(value);
  }

  return outObject;
}
