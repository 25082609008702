import { useContext } from "react";
import { ContextData } from "data/Context";
import { Link } from "react-router-dom";
import { ReactComponent as LogoBackground } from "svg/logoBackground.svg";
import { Button, Seal, HeroSteps } from "components";
import { useWindowDimensions } from "hooks";

export default function Home() {
  let contextReact = useContext(ContextData);
  const { width, height }: any = useWindowDimensions();

  return (
    <Link
      to="/Configurator"
      onClick={() => {
        contextReact.updater("swiperStep", 1);
      }}
    >
      <div className="homeHero">
        <div
          className="homeHero-imgCoverCon"
          style={{
            overflow: "hidden",
          }}
        >
          <LogoBackground className="homeHero-imgCover"></LogoBackground>
          <LogoBackground className="homeHero-imgCover"></LogoBackground>
        </div>
        <div className="homeHero-innerCon">
          <img
            src="\images\GGC-premium-big.png"
            alt=""
            width={width > 809 ? "400" : "290"}
            height={width > 809 ? "190" : "120"}
            className="homeHero-logo"
          />
          <div className="homeHero-textGrid">
            <div className="homeHero-textCon --Large">
              <h1 className="homeHero-headline">
                Design Your Own <br /> Premium Goo Goo
              </h1>
              <p className="homeHero-copy">
                We invented the first combination candy bar, now it’s your turn!
                Customize each step of your candy creation from delicious
                add-ins to the rich chocolate shell.
              </p>
            </div>
            <HeroSteps step="1"> Chocolate Shell </HeroSteps>
            <HeroSteps step="2"> Sweet Fillings</HeroSteps>
            <HeroSteps step="3"> Add-Ins </HeroSteps>
          </div>

          <Button
            big={true}
            text="  Tap to Begin"
            passClick={() => {
              contextReact.updater("swiperStep", 1);
            }}
          ></Button>
        </div>
      </div>
    </Link>
  );
}
