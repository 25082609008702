import React from "react";
import Button from "components/Button";
import GGCStoreFront, { PaymentIntentData } from "services/ggc-storefront-api";

interface IProps {}
interface IState {
  paymentIntents: PaymentIntentData[];
}

export default class DebugStripe extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      paymentIntents: [],
    };

    this.listPaymentIntents = this.listPaymentIntents.bind(this);
    this.cancelPaymentIntent = this.cancelPaymentIntent.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.listPaymentIntents();
    }, 2000);
  }

  listPaymentIntents() {
    console.log("Discovering readers");
    this.setState({
      paymentIntents: [],
    });

    GGCStoreFront.getInstance()
      .listPaymentIntents()
      .then(() => {
        let paymentIntents = GGCStoreFront.getInstance().paymentIntents;
        this.setState({
          paymentIntents: paymentIntents,
        });
      });
  }

  cancelPaymentIntent(paymentIntentID: string) {
    console.log("Canceling payment intent ", paymentIntentID);

    GGCStoreFront.getInstance()
      .cancelPayment(paymentIntentID)
      .then(() => {
        this.listPaymentIntents();
      });
  }

  render() {
    return (
      <div className="cart-page">
        <table>
          <tr>
            <td>
              <Button
                left={true}
                text={"Refresh"}
                passClick={this.listPaymentIntents}
              />
            </td>
            <td width={200}>ID</td>
            <td width={200}>Amount</td>
            <td width={200}>Cart ID</td>
            <td width={200}>Status</td>
            <td width={300}>Created</td>
            <td width={300}>Canceled</td>
          </tr>

          {this.state.paymentIntents?.map(
            (intent: PaymentIntentData, index: number) => {
              return (
                <tr key={`reader_${index}`}>
                  <td>
                    <Button
                      left={true}
                      text={"Cancel"}
                      passClick={() => {
                        this.cancelPaymentIntent(intent.id);
                      }}
                    />
                  </td>
                  <td>{intent.id}</td>
                  <td>{intent.amount}</td>
                  <td>{intent.cartID}</td>
                  <td>{intent.status}</td>
                  <td>{intent.created}</td>
                  <td>{intent.canceled_at}</td>
                  <td>{}</td>
                </tr>
              );
            }
          )}
        </table>
      </div>
    );
  }
}
