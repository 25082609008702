import { useContext, useEffect } from "react";
import { ContextData } from "data/Context";
import { Button, Seal, Ingredient } from "components";
import { Swiper, SwiperSlide } from "swiper/react";
import { chunkArray, useWindowDimensions, Portal } from "hooks";
import SwiperCore, { Navigation, Pagination } from "swiper";
SwiperCore.use([Navigation, Pagination]);

interface Item {
  name: string;
  info?: string;
  price?: number;
  id?: string;
}

interface IProps {
  step: string;
  title: string;
  copy: string;
  IngredientType: string;
  items: any;
  swiper: any;
  forward?: string;
  back?: string;
  namerPopup?: any;
  modelModeTarget?: number;
  modelModeFunction?: any;
  modelModeFunctionBack?: any;
  children?: any;
  active?: any;
  addOnModel?: any;
  mobileNav?: boolean;
}

interface GooGooItem {
  name?: string;
  shell?: Item;
  sweets: [Item?, Item?];
  addIns: [Item?, Item?, Item?];
  [key: string]: any;
}

let innerSwiper: any;
export default function ConfiguratorSlide({
  step,
  title,
  copy,
  IngredientType,
  items,
  swiper,
  forward,
  back,
  namerPopup,
  modelModeTarget,
  modelModeFunction,
  modelModeFunctionBack,
  children,
  active,
  addOnModel,
  mobileNav,
}: IProps) {
  const contextReact: any = useContext(ContextData);
  let innerSwiperWidth: any;
  let slideAmount: number;
  const { height, width }: any = useWindowDimensions();
  if (width > 809) {
    slideAmount = 6;
  } else {
    slideAmount = 4;
  }
  let itemsSorted: any = chunkArray(items, slideAmount);

  useEffect(() => {
    if (innerSwiper) {
      innerSwiper.update();
    }
  }, [active]);
  useEffect(() => {
    if (innerSwiper) {
      innerSwiper.update();
    }
  }, [contextReact]);

  if (items)
    return (
      <div
        className={
          "configurator-slide " +
          (contextReact.swiperStep === 1 ? " --active" : "")
        }
        style={{
          transform: `translateY(${
            active === true || active === undefined ? "0vh" : "100vh"
          })`,
        }}
      >
        {children}
        <div className="configurator-flex">
          <div className="configurator-step">
            <Seal>{step}</Seal>
          </div>
          <div>
            <h3>
              {title}
              <span>
                &nbsp;(
                {modelModeTarget === undefined
                  ? contextReact.currentGooGoo[IngredientType].length
                  : contextReact.cart[modelModeTarget][IngredientType].length}
                /{contextReact.globalVariables.itemLimits[IngredientType]})
              </span>
            </h3>
            <div className="configurator-slideCopy">{copy}</div>
          </div>
        </div>
        <hr className="configurator-slideRule" />
        {width <= 809 && modelModeTarget === undefined && !addOnModel ? (
          <div className="configurator-ingredientCon --mobileFlow --noPag">
            <div
              className={`configurator-ingredientSlide  --mobileFlow  ${
                IngredientType === "shell" ? " --shell" : ""
              }`}
            >
              {items.map((elementInner: any, i: number) => {
                console.log(modelModeTarget);
                return (
                  <Ingredient
                    title={elementInner.title}
                    item={elementInner}
                    src={elementInner.src}
                    ingredientType={IngredientType}
                    price={elementInner.price}
                    info={elementInner.info}
                    key={i + elementInner.title}
                    target={
                      modelModeTarget !== undefined ? modelModeTarget : null
                    }
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <Swiper
            className={
              itemsSorted.length > 1
                ? "configurator-ingredientCon"
                : " configurator-ingredientCon --noPag"
            }
            slidesPerView={1}
            spaceBetween={width > 809 ? -30 : 0}
            centeredSlides={true}
            pagination={true}
            watchOverflow={true}
            width={innerSwiperWidth}
            init={width > 809 ? true : false}
            allowSlideNext={itemsSorted.length > 1 ? true : false}
            allowSlidePrev={itemsSorted.length > 1 ? true : false}
            onSlideChange={(swiperInst) => {
              swiperInst.update();
            }}
            onSwiper={(swiperInst) => {
              innerSwiper = swiperInst;
              let getCon: any = document.querySelector(
                ".configurator-ingredientCon"
              );
              innerSwiperWidth = getCon.getBoundingClientRect().width;
              setTimeout(function () {
                swiperInst.update();
              }, 100);
              // setInterval(function () {
              //   swiperInst.update();
              // }, 1000);
              window.addEventListener("resize", () => {
                swiperInst.update();
                setTimeout(function () {
                  swiperInst.update();
                }, 500);
              });
            }}
          >
            {itemsSorted.map((element: any, i: number) => {
              // console.log(modelModeTarget);
              return (
                <SwiperSlide
                  className={`configurator-ingredientSlide  ${
                    IngredientType === "shell" ? " --shell" : ""
                  }`}
                  key={i + IngredientType}
                >
                  {element.map((elementInner: any) => {
                    // console.log(modelModeTarget);
                    return (
                      <Ingredient
                        title={elementInner.title}
                        item={elementInner}
                        src={elementInner.src}
                        ingredientType={IngredientType}
                        price={elementInner.price}
                        info={elementInner.info}
                        key={i + elementInner.title}
                        target={
                          modelModeTarget !== undefined ? modelModeTarget : null
                        }
                      />
                    );
                  })}
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}

        <div
          className={`configurator-buttonCon ${mobileNav ? "--inLine" : ""}`}
        >
          {back ? (
            <Button
              text={back}
              left={true}
              passClick={() => {
                if (modelModeFunctionBack) {
                  modelModeFunctionBack();
                } else {
                  swiper.slidePrev();
                  contextReact.updater("swiperStep", swiper.activeIndex + 1);
                }
              }}
            />
          ) : (
            <div></div>
          )}

          {namerPopup ? (
            <Button
              text={forward}
              disabled={
                contextReact.currentGooGoo[IngredientType].length >=
                contextReact.globalVariables.itemLimits[IngredientType]
                  ? false
                  : true
              }
              passClick={() => {
                setTimeout(function () {
                  contextReact.updater("swiperStep", swiper.activeIndex + 2);
                  namerPopup();
                }, 100);
              }}
            />
          ) : null}
          {modelModeTarget !== undefined && !namerPopup ? (
            <Button
              text={forward}
              disabled={
                contextReact.cart[modelModeTarget][IngredientType].length >=
                contextReact.globalVariables.itemLimits[IngredientType]
                  ? false
                  : IngredientType === "addOns"
                  ? false
                  : true
              }
              passClick={() => {
                modelModeFunction();
              }}
            />
          ) : null}

          {addOnModel ? (
            <Button
              text={forward}
              passClick={() => {
                modelModeFunction();
              }}
            />
          ) : null}
          {modelModeTarget === undefined && !namerPopup && !addOnModel ? (
            <Button
              text={forward}
              disabled={
                contextReact.currentGooGoo[IngredientType].length >=
                contextReact.globalVariables.itemLimits[IngredientType]
                  ? false
                  : true
              }
              passClick={() => {
                console.log(swiper);
                setTimeout(function () {
                  swiper.slideNext();

                  contextReact.updater("swiperStep", swiper.activeIndex + 1);
                }, 100);
              }}
            />
          ) : null}

          {mobileNav ? (
            <Portal id={`buttoncon`}>
              <div
                className="configurator-buttonCon --fixed"
                style={{
                  transform: `translateX(-${
                    100 * (contextReact.swiperStep - 1)
                  }%)`,
                }}
              >
                {back ? (
                  <Button
                    text={back}
                    left={true}
                    passClick={() => {
                      swiper.slidePrev();
                      contextReact.updater(
                        "swiperStep",
                        swiper.activeIndex + 1
                      );
                    }}
                  />
                ) : (
                  <div></div>
                )}

                {namerPopup ? (
                  <Button
                    text={forward}
                    disabled={
                      contextReact.currentGooGoo[IngredientType].length >=
                      contextReact.globalVariables.itemLimits[IngredientType]
                        ? false
                        : true
                    }
                    passClick={() => {
                      setTimeout(function () {
                        contextReact.updater(
                          "swiperStep",
                          swiper.activeIndex + 2
                        );
                        namerPopup();
                      }, 100);
                    }}
                  />
                ) : null}
                {modelModeTarget !== undefined && !namerPopup ? (
                  <Button
                    text={forward}
                    disabled={
                      contextReact.cart[modelModeTarget][IngredientType]
                        .length >=
                      contextReact.globalVariables.itemLimits[IngredientType]
                        ? false
                        : true
                    }
                    passClick={() => {
                      modelModeFunction();
                    }}
                  />
                ) : null}

                {addOnModel ? (
                  <Button
                    text={forward}
                    passClick={() => {
                      modelModeFunction();
                    }}
                  />
                ) : null}
                {modelModeTarget === undefined && !namerPopup && !addOnModel ? (
                  <Button
                    text={forward}
                    disabled={
                      contextReact.currentGooGoo[IngredientType].length >=
                      contextReact.globalVariables.itemLimits[IngredientType]
                        ? false
                        : true
                    }
                    passClick={() => {
                      console.log(swiper);
                      setTimeout(function () {
                        swiper.slideNext();
                        contextReact.updater(
                          "swiperStep",
                          swiper.activeIndex + 1
                        );
                      }, 100);
                    }}
                  />
                ) : null}
              </div>
            </Portal>
          ) : null}
        </div>
      </div>
    );
}
