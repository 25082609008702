import React from "react";

interface IProps {
  text: string;
  left?: boolean;
  big?: boolean;
  center?: boolean;
  extraClass?: string;
  passClick?: any;
  disabled?: boolean;
}

export default function Button({
  text,
  left,
  big,
  extraClass,
  passClick,
  disabled,
  center,
}: IProps) {
  return (
    <button
      className={`
      buttonMain
  ${left ? "--left" : null}
  ${big ? "--big" : null}
  ${center ? "--center" : null}
  ${disabled ? "is-disabled" : null}
  ${extraClass ? extraClass : null}
  `}
      onClick={passClick}
    >
      <div className="buttonMain-inner">{text}</div>
      {left ? (
        <svg
          width="160"
          height="40"
          viewBox="0 0 160 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.12514 5.53872e-05L158.54 6.85369e-05C158.78 -0.00214715 159.016 0.0549564 159.228 0.166286C159.441 0.277616 159.622 0.439637 159.755 0.637798C159.889 0.83596 159.971 1.06406 159.993 1.3015C160.016 1.53894 159.979 1.77832 159.886 1.99807L152.742 19.4605C152.601 19.8066 152.601 20.1934 152.742 20.5395L159.886 38.0019C159.979 38.2217 160.016 38.461 159.993 38.6985C159.971 38.9359 159.889 39.164 159.755 39.3621C159.622 39.5603 159.441 39.7224 159.228 39.8337C159.016 39.945 158.78 40.0022 158.54 39.9999L8.12514 39.9999C7.82154 39.9985 7.5263 39.9009 7.28233 39.7213C7.03837 39.5416 6.85841 39.2893 6.76862 39.0009L0.0964678 20.4595C-0.0321602 20.1388 -0.0321602 19.7812 0.0964679 19.4605L6.76862 0.919119C6.87327 0.646533 7.05932 0.412345 7.30184 0.248037C7.54435 0.0837298 7.83168 -0.00280308 8.12514 5.53872e-05V5.53872e-05Z"
            fill="#7299C6"
          />
        </svg>
      ) : null}
      {center ? (
        <svg
          width="196"
          height="40"
          viewBox="0 0 196 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M187.875 39.9999H37.4604C37.2205 40.0021 36.9838 39.945 36.7716 39.8337C36.5594 39.7224 36.3785 39.5604 36.2449 39.3622C36.1114 39.164 36.0294 38.9359 36.0066 38.6985C35.9837 38.4611 36.0206 38.2217 36.1139 38.0019L43.2583 20.5395C43.3991 20.1934 43.3991 19.8065 43.2583 19.4605L36.1139 1.99806C36.0206 1.77831 35.9837 1.53896 36.0066 1.30152C36.0294 1.06408 36.1114 0.836012 36.2449 0.63785C36.3785 0.439689 36.5594 0.277638 36.7716 0.166308C36.9838 0.0549777 37.2205 -0.00215363 37.4604 6.20756e-05H187.875C188.178 0.00143748 188.474 0.0990499 188.718 0.278713C188.962 0.458377 189.142 0.710724 189.231 0.99906L195.904 19.5405C196.032 19.8612 196.032 20.2187 195.904 20.5395L189.231 39.0809C189.127 39.3535 188.941 39.5876 188.698 39.7519C188.456 39.9163 188.168 40.0028 187.875 39.9999Z"
            fill="#B88B74"
          />
          <path
            d="M8.12514 5.53872e-05L158.54 6.85369e-05C158.78 -0.00214778 159.016 0.0549544 159.228 0.166286C159.441 0.277615 159.622 0.439636 159.755 0.637798C159.889 0.835957 159.971 1.06406 159.993 1.3015C160.016 1.53894 159.979 1.77832 159.886 1.99807L152.742 19.4605C152.601 19.8066 152.601 20.1935 152.742 20.5395L159.886 38.0019C159.979 38.2217 160.016 38.461 159.993 38.6985C159.971 38.9359 159.889 39.164 159.755 39.3621C159.622 39.5603 159.441 39.7224 159.228 39.8337C159.016 39.945 158.78 40.0022 158.54 39.9999L8.12514 39.9999C7.82153 39.9985 7.52629 39.9009 7.28233 39.7213C7.03836 39.5416 6.85841 39.2893 6.76862 39.0009L0.0964678 20.4595C-0.0321485 20.1388 -0.0321485 19.7812 0.0964679 19.4605L6.76862 0.919119C6.87328 0.646532 7.05931 0.412344 7.30184 0.248037C7.54435 0.0837307 7.83167 -0.00280185 8.12514 5.53872e-05Z"
            fill="#B88B74"
          />
        </svg>
      ) : null}

      {big ? (
        <svg
          width="300"
          height="75"
          viewBox="0 0 300 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M284.765 74.9999H2.73818C2.28835 75.004 1.84464 74.897 1.44679 74.6882C1.04894 74.4795 0.70961 74.1757 0.459214 73.8041C0.208818 73.4326 0.0552033 73.0049 0.0123445 72.5597C-0.0305142 72.1145 0.0386228 71.6657 0.213585 71.2536L13.6093 38.5115C13.8733 37.8627 13.8733 37.1373 13.6093 36.4885L0.213585 3.74636C0.0386228 3.33433 -0.0305142 2.88556 0.0123445 2.44035C0.0552033 1.99515 0.208818 1.56752 0.459214 1.19597C0.70961 0.824417 1.04894 0.520571 1.44679 0.311827C1.84464 0.103083 2.28835 -0.00403805 2.73818 0.000116392H284.765C285.335 0.00269527 285.888 0.185719 286.346 0.522588C286.803 0.859457 287.14 1.33261 287.309 1.87324L299.819 36.6384C300.06 37.2397 300.06 37.9101 299.819 38.5115L287.309 73.2766C287.113 73.7877 286.764 74.2268 286.309 74.5349C285.854 74.843 285.316 75.0052 284.765 74.9999V74.9999Z"
            fill="#E31B23"
          />
        </svg>
      ) : null}

      {!big && !left && !center ? (
        <svg
          width="160"
          height="40"
          viewBox="0 0 160 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M151.875 39.9999H1.46036C1.22045 40.0021 0.983807 39.945 0.771621 39.8337C0.559435 39.7224 0.378459 39.5604 0.244914 39.3622C0.11137 39.164 0.0294417 38.9359 0.00658374 38.6985C-0.0162743 38.4611 0.0205989 38.2217 0.113912 38.0019L7.25831 20.5395C7.3991 20.1934 7.3991 19.8065 7.25831 19.4605L0.113912 1.99806C0.0205989 1.77831 -0.0162743 1.53896 0.00658374 1.30152C0.0294417 1.06408 0.11137 0.836012 0.244914 0.63785C0.378459 0.439689 0.559435 0.277638 0.771621 0.166308C0.983807 0.0549777 1.22045 -0.00215363 1.46036 6.20756e-05H151.875C152.178 0.00143748 152.474 0.0990499 152.718 0.278713C152.962 0.458377 153.142 0.710724 153.231 0.99906L159.904 19.5405C160.032 19.8612 160.032 20.2187 159.904 20.5395L153.231 39.0809C153.127 39.3535 152.941 39.5876 152.698 39.7519C152.456 39.9163 152.168 40.0028 151.875 39.9999V39.9999Z" />
        </svg>
      ) : null}
    </button>
  );
}

function ArrowLeft() {
  return <div className="sdf"></div>;
}
function ArrowRight() {
  return <div className="sdf"></div>;
}
