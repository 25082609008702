import React from "react";
import { ReactComponent as Background } from "svg/logoBackground.svg";

export default function LogoBackground() {
  return (
    <div className="homeHero-imgCoverCon">
      <Background className="homeHero-imgCover"></Background>
      <Background className="homeHero-imgCover"></Background>
      {/* <Background className="homeHero-imgCover"></Background>
      <Background className="homeHero-imgCover"></Background> */}
    </div>
  );
}
