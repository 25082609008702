/**
 * @file chunkArray.tsx
 * @author Cody Parnell
 * @description Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunk_size {Integer} Size of every group
 */

export default function chunkArray(myArray: any, chunk_size: number) {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];
  let myChunk;

  for (index = 0; index < arrayLength; index += chunk_size) {
    myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}
