/**
 * @file Context.tsx
 * @author Cody Parnell
 * @description defines ui context
 */

import React from "react";
import { clone } from "hooks";
import GGCStoreFront, { ShopifyCart } from "services/ggc-storefront-api";
import { useHistory } from "react-router-dom";

interface IProps {}

/**
 * defines ingredient items structure
 * temp
 */

interface Item {
  title: string;
  src?: string;
  info?: string;
  price: number;
  description: string;
  available: boolean;
  id: number;
  [key: string]: any;
}

interface GooGooItem {
  name?: string;
  count?: number;
  shell?: [Item?] | undefined[];
  sweets?: [Item?, Item?] | undefined[];
  addIns?: [Item?, Item?, Item?] | undefined[];
  addOns?: [Item?, Item?, Item?] | undefined[];
  [key: string]: any;
}

/**
 * interface for all the variables and functions of the context
 */
interface IState {
  globalVariables: any;
  isFetching: boolean;
  swiperStep: number;
  updater: Function;
  currentGooGoo: GooGooItem;
  addGooGooToCart: any;
  addIngredient: any;
  removeIngredient: any;
  createTempGooGoo: any;
  restoreTempGooGoo: any;
  resetHistory: any;
  nameGooGoo: any;
  backToEditor: any;
  cart?: any;
  shopifyCart?: any;
  historyGooGoo: any;
  cartCount: number;
  flushCart: any;
  ingredients: any;
  cartSync: any;
  [key: string]: any;
}

let ContextData = React.createContext(null);

export default class Context extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      /**
       * global variables for easy access
       */
      globalVariables: {
        itemLimits: {
          shell: 1,
          sweets: 2,
          addIns: 3,
          addOns: 1,
        },
        price: 5.99,
      },
      /**
       * tracks the loading state.
       */
      isFetching: false,
      /**
       * the swiper step tracks what ingredient step is currently active.
       */
      swiperStep: 1,
      ingredients: {
        shells: [],
        sweets: [],
        addIns: [],
        addOns: [],
      },
      cart: [],
      shopifyCart: [],
      historyGooGoo: {},
      cartCount: 0,
      cartPriceTotal: 0,

      /**
       * gooGoo being configured pre-cart.
       */
      currentGooGoo: {
        name: null,
        count: 1,
        shell: [],
        sweets: [],
        addIns: [],
        addOns: [],
      },

      /**
       * goo goo price calculator
       */

      /**
       *
       * this is a multi purpose function used to universally add ingredients no matter their location
       *
       * @param type type of ingredient
       * @param ingredient the name of the new ingredient
       * @param cartTarget? optional: the index of the cart gooGoo being targeted
       *
       */

      addIngredient: (
        type: "shell" | "sweets" | "addIns" | "addOns",
        ingredient: any,
        cartTarget?: number
      ) => {
        //get current ingredient state,
        let targetGooGoo: any;
        // console.log(cartTarget);
        if (cartTarget !== null) {
          targetGooGoo = this.state.cart[cartTarget][type];
        } else {
          targetGooGoo = this.state.currentGooGoo[type];
        }

        //see how many items it can have
        let maxNumber: number;
        let otherParam: any = true;
        if (type === "shell") {
          maxNumber = 2;
          otherParam = this.checkDuplicateItems(targetGooGoo, ingredient);
        } else if (type === "sweets") {
          maxNumber = this.state.globalVariables.itemLimits.sweets;
        } else if (type === "addIns") {
          maxNumber = this.state.globalVariables.itemLimits.addIns;
        } else if (type === "addOns") {
          maxNumber = 2;
          otherParam = this.checkDuplicateItems(targetGooGoo, ingredient);
        }

        // checks to make sure that their is space for more ingredients & that no ingredient can be added more than two times.
        if (targetGooGoo.length < maxNumber && otherParam) {
          // updates either the cart or the current gogo
          if (cartTarget !== null) {
            let updateTemp: any = [...this.state.cart];
            if (type === "addOns" || type === "shell") {
              updateTemp[cartTarget][type] = [];
              updateTemp[cartTarget][type].push(ingredient);
            } else {
              updateTemp[cartTarget][type].push(ingredient);
            }
            this.setState({ cart: updateTemp });

            let preGooGoo: Item[] = [];
            if (this.state.cart[cartTarget].shell[0]) {
              preGooGoo.push(this.state.cart[cartTarget].shell[0]);
            }
            if (this.state.cart[cartTarget].sweets[0]) {
              preGooGoo.push(this.state.cart[cartTarget].sweets[0]);
            }
            if (this.state.cart[cartTarget].sweets[1]) {
              preGooGoo.push(this.state.cart[cartTarget].sweets[1]);
            }
            if (this.state.cart[cartTarget].addIns[0]) {
              preGooGoo.push(this.state.cart[cartTarget].addIns[0]);
            }
            if (this.state.cart[cartTarget].addIns[1]) {
              preGooGoo.push(this.state.cart[cartTarget].addIns[1]);
            }
            if (this.state.cart[cartTarget].addIns[2]) {
              preGooGoo.push(this.state.cart[cartTarget].addIns[2]);
            }
            if (this.state.cart[cartTarget].addOns[0]) {
              preGooGoo.push(this.state.cart[cartTarget].addOns[0]);
            }
            this.state.updater("isFetching", true);
            GGCStoreFront.getInstance().updateGooGoo(
              this.state.shopifyCart.lineItems[cartTarget].id,
              this.state.shopifyCart.lineItems[cartTarget].title,
              this.state.shopifyCart.lineItems[cartTarget].quantity,
              preGooGoo,
              (cart: ShopifyCart) => {
                this.setState({ shopifyCart: cart });
                this.state.updater("isFetching", false);
              }
            );
          } else {
            // console.log("editing current goo goo");
            let updateTemp: any = { ...this.state.currentGooGoo };
            if (type === "addOns" || type === "shell") {
              updateTemp[type] = [];
              updateTemp[type].push(ingredient);
            } else {
              updateTemp[type].push(ingredient);
            }
            this.setState({ currentGooGoo: updateTemp });
          }
          // console.log(this.state.currentGooGoo);
        } else {
          // console.log("item full");
        }

        // if the target is addons and the current ingredient already exists we remove it

        if (!otherParam && (type === "addOns" || type === "shell")) {
          this.state.removeIngredient(type, ingredient, cartTarget);
        }
      },

      /**
       *
       *  a multi purpose function used to universally remove ingredients no matter their location
       *
       * @param type type of ingredient
       * @param ingredient the name of the new ingredient
       * @param cartTarget? optional: the index of the cart gooGoo being targeted
       *
       */
      removeIngredient: (
        type: string,
        ingredient: string,
        cartTarget: number
      ) => {
        // console.log("removing...");
        // console.log(cartTarget);

        if (cartTarget !== null) {
          let updateTemp: any = [...this.state.cart];

          updateTemp[cartTarget][type] = this.removeFirstMatch(
            updateTemp[cartTarget][type],
            ingredient
          );

          setTimeout(() => {
            this.setState({ cart: updateTemp });
            let preGooGoo: Item[] = [];
            if (this.state.cart[cartTarget].shell[0]) {
              preGooGoo.push(this.state.cart[cartTarget].shell[0]);
            }
            if (this.state.cart[cartTarget].sweets[0]) {
              preGooGoo.push(this.state.cart[cartTarget].sweets[0]);
            }
            if (this.state.cart[cartTarget].sweets[1]) {
              preGooGoo.push(this.state.cart[cartTarget].sweets[1]);
            }
            if (this.state.cart[cartTarget].addIns[0]) {
              preGooGoo.push(this.state.cart[cartTarget].addIns[0]);
            }
            if (this.state.cart[cartTarget].addIns[1]) {
              preGooGoo.push(this.state.cart[cartTarget].addIns[1]);
            }
            if (this.state.cart[cartTarget].addIns[2]) {
              preGooGoo.push(this.state.cart[cartTarget].addIns[2]);
            }
            if (this.state.cart[cartTarget].addOns[0]) {
              preGooGoo.push(this.state.cart[cartTarget].addOns[0]);
            }
            this.state.updater("isFetching", true);
            GGCStoreFront.getInstance().updateGooGoo(
              this.state.shopifyCart.lineItems[cartTarget].id,
              this.state.shopifyCart.lineItems[cartTarget].title,
              this.state.shopifyCart.lineItems[cartTarget].quantity,
              preGooGoo,
              (cart: ShopifyCart) => {
                this.setState({ shopifyCart: cart });
                this.state.updater("isFetching", false);
              }
            );
          }, 100);
        } else {
          let updateTemp: any = { ...this.state.currentGooGoo };
          updateTemp[type] = this.removeFirstMatch(
            updateTemp[type],
            ingredient
          );
          this.setState({ currentGooGoo: updateTemp });
        }
      },

      /**
       * function that creates the history cart object
       *
       * @param gooGooCartTarget the index of the gooGOo that should be copied
       */
      createTempGooGoo: (gooGooCartTarget: number | "currentGooGoo") => {
        let updateTemp: any;
        if (gooGooCartTarget === "currentGooGoo") {
          updateTemp = clone(this.state.currentGooGoo);
        } else {
          updateTemp = clone(this.state.cart[gooGooCartTarget]);
        }
        this.setState({ historyGooGoo: updateTemp });
      },

      /**
       *  function that restores from the history cart
       *
       * @param index the index of the gooGOo that should be copied
       */
      restoreTempGooGoo: (index: number | "currentGooGoo") => {
        if (index === "currentGooGoo") {
          this.setState({ currentGooGoo: this.state.historyGooGoo });
          this.setState({ historyGooGoo: {} });
        } else {
          let updateTemp: any;
          updateTemp = clone(this.state.cart);

          // console.log(this.state.currentGooGoo);
          // console.log("restoring");
          updateTemp[index] = this.state.historyGooGoo;

          let preGooGoo: Item[] = [];
          preGooGoo.push(this.state.cart[index].shell[0]);
          preGooGoo.push(this.state.cart[index].sweets[0]);
          preGooGoo.push(this.state.cart[index].sweets[1]);
          preGooGoo.push(this.state.cart[index].addIns[0]);
          preGooGoo.push(this.state.cart[index].addIns[1]);
          preGooGoo.push(this.state.cart[index].addIns[2]);
          if (this.state.cart[index].addOns[0]) {
            preGooGoo.push(this.state.cart[index].addOns[0]);
          }
          this.state.updater("isFetching", true);
          GGCStoreFront.getInstance().updateGooGoo(
            this.state.shopifyCart.lineItems[index].id,
            this.state.shopifyCart.lineItems[index].title,
            this.state.shopifyCart.lineItems[index].quantity,
            preGooGoo,
            (cart: ShopifyCart) => {
              this.setState({ shopifyCart: cart });
              this.state.updater("isFetching", false);
            }
          );
          this.setState({ cart: updateTemp });
          this.setState({ historyGooGoo: {} });
        }

        // this.state.resetConfiguratorCart();
      },

      addGooGooToCart: () => {
        let updateTemp: any[];
        // console.log("adding googoo to cart");
        updateTemp = [...this.state.cart];

        updateTemp.push(this.state.currentGooGoo);
        this.setState({ cart: updateTemp });
        // console.log(this.state);
        this.state.updateCartCount();
        setTimeout(() => {
          this.state.updateCartCount();
        }, 100);

        let preGooGoo: Item[] = [];
        if (this.state.currentGooGoo.shell[0]) {
          preGooGoo.push(this.state.currentGooGoo.shell[0]);
        }
        if (this.state.currentGooGoo.sweets[0]) {
          preGooGoo.push(this.state.currentGooGoo.sweets[0]);
        }
        if (this.state.currentGooGoo.sweets[1]) {
          preGooGoo.push(this.state.currentGooGoo.sweets[1]);
        }
        if (this.state.currentGooGoo.addIns[0]) {
          preGooGoo.push(this.state.currentGooGoo.addIns[0]);
        }
        if (this.state.currentGooGoo.addIns[1]) {
          preGooGoo.push(this.state.currentGooGoo.addIns[1]);
        }
        if (this.state.currentGooGoo.addIns[2]) {
          preGooGoo.push(this.state.currentGooGoo.addIns[2]);
        }
        if (this.state.currentGooGoo.addOns[0]) {
          preGooGoo.push(this.state.currentGooGoo.addOns[0]);
        }

        this.state.updater("isFetching", true);
        GGCStoreFront.getInstance().addGooGoo(
          this.state.currentGooGoo.name,
          this.state.currentGooGoo.count,
          preGooGoo,
          (cart: ShopifyCart) => {
            this.setState({ shopifyCart: cart });
            this.state.updater("isFetching", false);
          }
        );
      },

      incrementGooGoo: (index: number, increment: "++" | "--") => {
        let updateTemp: any = [...this.state.cart];

        // possible fall back
        // if (updateTemp[index].count === 0) {
        //   this.state.removeGooGoo(index);
        // }

        if (increment === "++" && this.state.cartCount < 7) {
          updateTemp[index].count++;
        } else if (increment === "--") {
          updateTemp[index].count--;
        }
        if (updateTemp[index].count === 0) {
          this.state.removeGooGoo(index);
        } else {
          this.setState({ cart: updateTemp });
        }

        let preGooGoo: Item[] = [];
        preGooGoo.push(this.state.cart[index].shell[0]);
        preGooGoo.push(this.state.cart[index].sweets[0]);
        preGooGoo.push(this.state.cart[index].sweets[1]);
        preGooGoo.push(this.state.cart[index].addIns[0]);
        preGooGoo.push(this.state.cart[index].addIns[1]);
        preGooGoo.push(this.state.cart[index].addIns[2]);
        if (this.state.cart[index].addOns[0]) {
          preGooGoo.push(this.state.cart[index].addOns[0]);
        }
        if (
          increment === "++" ||
          (increment === "--" && this.state.cartCount < 7)
        ) {
          this.state.updater("isFetching", true);
          GGCStoreFront.getInstance().updateGooGoo(
            this.state.shopifyCart.lineItems[index].id,
            this.state.shopifyCart.lineItems[index].title,
            updateTemp[index].count,
            preGooGoo,
            (cart: ShopifyCart) => {
              this.setState({ shopifyCart: cart });
              this.state.updater("isFetching", false);
            }
          );
        }

        this.state.updateCartCount();
      },

      updateCartCount: () => {
        let updateTemp: any = this.state.cartCount;

        let getCount: number = 0;
        this.state.cart.forEach((e: any) => {
          // console.log(getCount);
          // console.log(e.count);
          // console.log(e);
          getCount = getCount + e.count;
        });

        this.setState({ cartCount: getCount });
      },
      nameGooGoo: (newName: string) => {
        let updateTemp: any = { ...this.state.currentGooGoo };
        updateTemp.name = newName;
        this.setState({ currentGooGoo: updateTemp });
      },
      /*
       * I have no good way to name this
       */
      backToEditor: () => {
        if (this.state.cart.length > 0) {
          this.setState({
            currentGooGoo: this.state.cart[this.state.cart.length - 1],
          });
          this.state.removeGooGoo(this.state.cart.length - 1);

          // GGCStoreFront.getInstance().deleteCart((cart: ShopifyCart) => {
          //   this.setState({ shopifyCart: cart });
          // });

          // GGCStoreFront.getInstance().removeGooGoo(
          //   this.state.shopifyCart.lineItems[this.state.cart.length - 1].id,
          //   (cart: ShopifyCart) => {
          //     this.setState({ shopifyCart: cart });
          //   }
          // );
        } else {
        }
        setTimeout(() => {
          this.state.updateCartCount();
        }, 100);
      },

      removeGooGoo: (index: number) => {
        let updateTemp: any = [...this.state.cart];
        updateTemp.splice(index, 1);
        this.setState({ cart: updateTemp });

        if (this.state.cart.length === 1) {
          console.log("deleting cart");
          this.state.updater("isFetching", true);
          GGCStoreFront.getInstance().deleteCart(() => {
            this.setState({ shopifyCart: [] });
            this.state.updater("isFetching", false);
          });
        } else {
          console.log("deleting item");
          this.state.updater("isFetching", true);
          GGCStoreFront.getInstance().removeGooGoo(
            this.state.shopifyCart.lineItems[index].id,
            (cart: ShopifyCart) => {
              this.setState({ shopifyCart: cart });
              this.state.updater("isFetching", false);
            }
          );
        }

        setTimeout(() => {
          this.state.updateCartCount();
        }, 100);
      },
      resetConfiguratorCart: () => {
        this.setState({
          currentGooGoo: {
            name: null,
            count: 1,
            shell: [],
            sweets: [],
            addIns: [],
            addOns: [],
          },
        });
      },
      resetHistory: () => {
        this.setState({
          historyGooGoo: {
            name: null,
            count: 1,
            shell: [],
            sweets: [],
            addIns: [],
            addOns: [],
          },
        });
      },
      flushCart: () => {
        this.state.resetConfiguratorCart();
        this.setState({ cart: [] });
        this.state.updateCartCount();
        this.state.updater("isFetching", true);
        GGCStoreFront.getInstance().deleteCart((data: any) => {
          console.log("Deleted order");
          console.log(data);
          this.state.updater("isFetching", false);
        });
      },

      cartSync: () => {
        // GGCStoreFront.getInstance().deleteCart((data: any) => {
        //   console.log("Deleted order");
        //   console.log(data);
        // });
        // for (let index = 0; index < this.state.cart.length; index++) {
        //   let preGooGoo: Item[] = [];
        //   preGooGoo.push(this.state.cart[index].shell[0]);
        //   preGooGoo.push(this.state.cart[index].sweets[0]);
        //   preGooGoo.push(this.state.cart[index].sweets[1]);
        //   preGooGoo.push(this.state.cart[index].addIns[0]);
        //   preGooGoo.push(this.state.cart[index].addIns[1]);
        //   preGooGoo.push(this.state.cart[index].addIns[2]);
        //   if (this.state.cart[index].addOns[0]) {
        //     preGooGoo.push(this.state.cart[index].addOns[0]);
        //   }
        //   GGCStoreFront.getInstance().addGooGoo(
        //     this.state.cart[index].name,
        //     this.state.cart[index].count,
        //     preGooGoo,
        //     (cart: ShopifyCart) => {
        //       this.setState({ shopifyCart: cart });
        //     }
        //   );
        // }
      },

      updater: (stateTarget: string, newValue?: any) => {
        /**
         * the context updater, this can be called to update parts of the context. the options for stateTarget or the path names
         */

        const paths: any = {
          example: () => {
            this.arrayModifier("placeholder", stateTarget, newValue);
          },
          swiperStep: () => {
            this.setState({ swiperStep: newValue });
          },
          ingredients: () => {
            this.setState({ ingredients: newValue });
          },
          isFetching: () => {
            this.setState({ isFetching: newValue });
          },
        };

        /**
         * iterates through the path keys to see which one is being called
         */

        Object.keys(paths).forEach((element) => {
          if (element === stateTarget) {
            paths[element]();
          }
        });
      },
    };
  }

  /**
   * updater function used in the updater function for immutability
   */

  arrayModifier = (arrayTarget: string, target: string, newValue: any) => {
    let updateArray = { ...this.state[arrayTarget] };
    if (newValue !== null) {
      if (this.state[arrayTarget] !== newValue) {
        updateArray[target] = newValue;
        this.setState({ [arrayTarget]: updateArray });
      }
    }
  };

  removeFirstMatch(arr: any, value: any) {
    const isDuplicate = (element: any) => element.title === value.title;
    var index = arr.findIndex(isDuplicate);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  checkDuplicateItems = (targetGooGoo: any, newValue: any) => {
    // console.log(targetGooGoo);
    // console.log(newValue);
    let result: any = targetGooGoo.filter(
      (ingredient: any) => ingredient.title === newValue.title
    );
    if (result.length < 1) {
      // console.log(result.length);
      return true;
    } else {
      // console.log(result.length);
      return false;
    }
  };

  componentWillUnmount() {
    document.addEventListener("keyup", closeKeyBoard);
  }

  componentDidMount() {
    document.addEventListener("keyup", closeKeyBoard);

    GGCStoreFront.getInstance().initialize(this.state.updater, () => {
      if (localStorage.getItem("oldState")) {
        // const oldState: any = JSON.parse(localStorage.getItem("oldState"));
        // this.setState({ cart: oldState.cart });
        // this.setState({ cartCount: oldState.cartCount });
        // oldState.cart.forEach((element: Item) => {
        //   let preGooGoo: Item[] = [];
        //   preGooGoo.push(element.shell[0]);
        //   preGooGoo.push(element.sweets[0]);
        //   preGooGoo.push(element.sweets[1]);
        //   preGooGoo.push(element.addIns[0]);
        //   preGooGoo.push(element.addIns[1]);
        //   preGooGoo.push(element.addIns[2]);
        //   if (element.addOns[0]) {
        //     preGooGoo.push(element.addOns[0]);
        //   }
        //   GGCStoreFront.getInstance().addGooGoo(
        //     element.name,
        //     element.count,
        //     oldState.preGooGoo,
        //     (cart: ShopifyCart) => {
        //       this.setState({ shopifyCart: cart });
        //     }
        //   );
        // });
      }
    });

    window.addEventListener(
      "beforeunload",
      function (e) {
        GGCStoreFront.getInstance().deleteCart((data: any) => {
          console.log("Deleted order");
          console.log(data);
        });
      },
      false
    );
  }

  render() {
    // localStorage.setItem("oldState", JSON.stringify(this.state));
    console.log(this.state);
    return (
      <>
        <ContextData.Provider value={this.state}>
          {this.props.children}
        </ContextData.Provider>
      </>
    );
  }
}

export { ContextData };

function closeKeyBoard(event: any) {
  // console.log(document.activeElement);
  if (event.key === "Enter") {
    if (document.activeElement) {
      const active: any = document.activeElement;
      active.blur();
      // console.log("close input");
    }
  }
}
