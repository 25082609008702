/**
 * normalizes vh units on mobile devices
 * should be called after components have been mounted
 */

export default function ViewportNormalize() {
  /**
   * We listen to the resize event
   */
  //console.log("called");
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    /**
     * We execute the same script as before
     */
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
}
